import React from 'react'
import Intermediate8 from '../components/Intermediate/Intermediate8'
import Header from '../components/Header/Header'
import Footer from '../components/Footer/Footer'
import ReactHelmet from '../components/ReactHelmet'

const PdfCompressor3 = () => {
    return (
        <div className='flex flex-col justify-between min-h-[100vh]'>
            <ReactHelmet title='Free PDF Compressor - Compress PDF Online' description='Reduce PDF file size effortlessly with our efficient PDF compressor tool. Compress large PDFs while maintaining quality, making them easy to share, store, and upload. "'/>
             <Header />
            <Intermediate8 />
            <Footer />
        </div>

    )
}

export default PdfCompressor3