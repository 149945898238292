import React, { useState } from 'react'
import qrcode, { QRContactDataType } from '../../utils/qrcode'
import { Form, Formik } from 'formik'
import FormikInputField from '../Formik/FormikInputField'
import * as yup from 'yup'

const QRFormContact: React.FC<QRFormComponentProps> = ({ onSubmit }) => {
  // const [formValues, setFormValues] = useState<QRContactDataType>({ name: '', org: '', title: '', ph: '', url: '', email: '',mobPh:'',fax:'',address:{postcode:'',country:'',street:'',region:'',city:''}, })
  const initValues: QRContactDataType = { name: '', org: '', title: '', ph: '', url: '', email: '',mobPh:'',fax:'',address:{postcode:'',country:'',street:'',region:'',city:''}}
  const phoneNoSchema = yup.string()
    .test('invalid-phone-no', 'Invalid Phone Number', (value) => value ? /^\+?\d+(?:[- ]\d+)*$/.test(value) : true)
    const urlSchema = yup.string()
    .test('invalid-url', 'Invalid website', (value) => value ? /\w+\.[A-Za-z]+$/.test(value) : true)
  const validationSchema = yup.object({
     name:yup.string(),
     org: yup.string(), 
     title: yup.string(),
     ph: phoneNoSchema, 
     url: urlSchema, 
     email: yup.string().email(),
     mobPh: phoneNoSchema,
    fax: yup.string().test("invalid-fax","Invalid Fax",(value) => value ? /^\d+$/.test(value) : true),
    address: yup.object().shape({
      postcode: yup.string().test("invalid-postcode","Invalid Postcode",(value) => value ? /^\d+$/.test(value) : true),
      country: yup.string(),
      region:yup.string(),
      street:yup.string(),
      city:yup.string()
    })
    })

  return (
    <div className="md:px-4 sm:px-0 px-0 dark:bg-gray-800">
      <div className='md:ml-6 sm:ml-0 ml-0 min-h-[20rem] sm:p-8 p-4 bg-white shadow-xl rounded-md'>
        <div className="relative">
          <Formik 
          onSubmit={(values, actions) => { 
            if (onSubmit) onSubmit(qrcode.generateVCardStr(values))
          }} validateOnBlur={true}  enableReinitialize={true}  validateOnChange={true} initialValues={initValues} validationSchema={validationSchema} >
            {(actions) => {
              return (
                <Form 
                onChange={(e)=>{
                  // setFormValues(actions.values)
                  actions.submitForm()
                }
                }
                   >
                  <div className="grid gap-6 mb-6">
                    {/* <FormikInputField
                      // onChange={e => {
              
                      //   setFormValues({ ...formValues, firstName: e.currentTarget.value })
                      //   // if (onSubmit) onSubmit(qrcode.generateVCardStr({ ...formValues, firstName: e.currentTarget.value === '' ? undefined : e.currentTarget.value }))
                      // }}
                      label="firstName"
                      placeholder=""
                      name='First Name' /> */}
                    <FormikInputField
                      onChange={e => {
                        actions.setFieldValue('name',e.target.value)
                        
                      }}
                      label="name"
                      placeholder=""
                      name='Name' />
                    <FormikInputField
                      // onChange={e => {
                        
                      //   setFormValues({ ...formValues, org: e.currentTarget.value })
                      //   // if (onSubmit) onSubmit(qrcode.generateVCardStr({ ...formValues, org: e.currentTarget.value === '' ? undefined : e.currentTarget.value }))
                      // }}
                      label="org"
                      placeholder=""
                      name='Organization' />
                      <FormikInputField
                      // onChange={e => {
                        
                      //   setFormValues({ ...formValues, title: e.currentTarget.value })
                      //   // if (onSubmit) onSubmit(qrcode.generateVCardStr({ ...formValues, title: e.currentTarget.value === '' ? undefined : e.currentTarget.value }))
                      // }}
                      label="title"
                      placeholder=""
                      name='Title' />
                      <FormikInputField
                      // onChange={e => {
                        
                      //   setFormValues({ ...formValues, email: e.currentTarget.value })
                      //   // if (onSubmit) onSubmit(qrcode.generateVCardStr({ ...formValues, email: e.currentTarget.value === '' ? undefined : e.currentTarget.value }))
                      // }}
                      label="email"
                      placeholder=""
                      name='Email' />
                    <FormikInputField
                      // onChange={e => {
                        
                      //   setFormValues({ ...formValues, ph: e.currentTarget.value })
                      //   // if (onSubmit) onSubmit(qrcode.generateVCardStr({ ...formValues, ph: e.currentTarget.value === '' ? undefined : e.currentTarget.value }))
                      // }}
                      label="ph"
                      placeholder=""
                      name='Phone' />
                      <FormikInputField
                      // onChange={e => {
                        
                      //   setFormValues({ ...formValues, mobPh: e.currentTarget.value })
                      //   // if (onSubmit) onSubmit(qrcode.generateVCardStr({ ...formValues, mobPh: e.currentTarget.value === '' ? undefined : e.currentTarget.value }))
                      // }}
                      label="mobPh"
                      placeholder=""
                      name='Mobile Phone' />
                      <FormikInputField
                      // onChange={e => {
                        
                      //   setFormValues({ ...formValues, fax: e.currentTarget.value })
                      //   // if (onSubmit) onSubmit(qrcode.generateVCardStr({ ...formValues, fax: e.currentTarget.value === '' ? undefined : e.currentTarget.value }))
                      // }}
                      label="fax"
                      placeholder=""
                      name='Fax' />
                      <FormikInputField
                      // onChange={e => {
                        
                      //   setFormValues({ ...formValues, title: e.currentTarget.value })
                      //   // if (onSubmit) onSubmit(qrcode.generateVCardStr({ ...formValues, title: e.currentTarget.value === '' ? undefined : e.currentTarget.value }))
                      // }}
                      label="address.street"
                      placeholder=""
                      name='Street' />
                      <FormikInputField
                      // onChange={e => {
                        
                      //   setFormValues({ ...formValues, org: e.currentTarget.value })
                      //   // if (onSubmit) onSubmit(qrcode.generateVCardStr({ ...formValues, org: e.currentTarget.value === '' ? undefined : e.currentTarget.value }))
                      // }}
                      label="address.city"
                      placeholder=""
                      name='City' />
                      <FormikInputField
                      // onChange={e => {
                        
                      //   setFormValues({ ...formValues, org: e.currentTarget.value })
                      //   // if (onSubmit) onSubmit(qrcode.generateVCardStr({ ...formValues, org: e.currentTarget.value === '' ? undefined : e.currentTarget.value }))
                      // }}
                      label="address.region"
                      placeholder=""
                      name='Region' />
                      <FormikInputField
                      // onChange={e => {
                        
                      //   setFormValues({ ...formValues, address:{...formValues.address, postcode:e.currentTarget.value}})
                      //   // if (onSubmit) onSubmit(qrcode.generateVCardStr({...formValues,address:e.currentTarget.value===''? formValues.address: {...formValues.address, postcode:e.currentTarget.value} }))
                      //   // if (onSubmit) onSubmit(qrcode.generateVCardStr( formValues))
                      // }}
                      label="address.postcode"
                      placeholder=""
                      name='Postcode'
                      />
                      <FormikInputField
                      // onChange={e => {
                        
                      //   setFormValues({ ...formValues, address:{...formValues.address, country:e.currentTarget.value}})
                      //   // if (onSubmit) onSubmit(qrcode.generateVCardStr({ ...formValues,address:e.currentTarget.value===''? formValues.address: {...formValues.address, country:e.currentTarget.value}}))
                      //   // if (onSubmit) onSubmit(qrcode.generateVCardStr(formValues))
                      // }}
                      label="adress.country"
                      placeholder=""
                      name='Country' />
                    <FormikInputField
                      // onChange={e => {
                        
                      //   setFormValues({ ...formValues, url: e.currentTarget.value })
                      //   // if (onSubmit) onSubmit(qrcode.generateVCardStr({ ...formValues, url: e.currentTarget.value === '' ? undefined : e.currentTarget.value }))
                      // }}
                      label="url"
                      placeholder=""
                      name='Website URL' />                    
                  </div>
                </Form>
              )
            }}
          </Formik>
          {/* <form>
            <div className="grid gap-6 mb-6">
              <div>
                <label className="block mb-2 text-sm font-medium text-gray-900 dark:text-white">First name</label>
                <input
                  value={formValues.firstName}
                  onChange={e => {
                    setFormValues({ ...formValues, firstName: e.currentTarget.value })
                    if (onSubmit) onSubmit(qrcode.generateVCardStr({ ...formValues, firstName: e.currentTarget.value === '' ? undefined : e.currentTarget.value }))     
                  }}
                  type="text" id="first_name" className="bg-transparent border-transparent border-b-gray-300 focus:outline-transparent focus:border-transparent focus:border-b-orange-theme ring-transparent focus:ring-transparent outline-none focus:border-orange-theme w-full" required />
              </div>
              <div>
                <label className="block mb-2 text-sm font-medium text-gray-900 dark:text-white">Last name</label>
                <input
                  value={formValues.lastName}
                  onChange={e => {
                    setFormValues({ ...formValues, lastName: e.currentTarget.value })
                    if (onSubmit) onSubmit(qrcode.generateVCardStr({ ...formValues, lastName: e.currentTarget.value === '' ? undefined : e.currentTarget.value })) 
                  }}
                  type="text" id="last_name" className="bg-transparent border-transparent border-b-gray-300 focus:outline-transparent focus:border-transparent focus:border-b-orange-theme ring-transparent focus:ring-transparent outline-none focus:border-orange-theme w-full" required />
              </div>
              <div>
                <label className="block mb-2 text-sm font-medium text-gray-900 dark:text-white">Company</label>
                <input
                  value={formValues.org}
                  onChange={e => {
                    setFormValues({ ...formValues, org: e.currentTarget.value })
                    if (onSubmit) onSubmit(qrcode.generateVCardStr({ ...formValues, org: e.currentTarget.value === '' ? undefined : e.currentTarget.value }))  
                  }}
                  type="text" id="company" className="bg-transparent border-transparent border-b-gray-300 focus:outline-transparent focus:border-transparent focus:border-b-orange-theme ring-transparent focus:ring-transparent outline-none focus:border-orange-theme w-full" required />
              </div>
              <div>
                <label className="block mb-2 text-sm font-medium text-gray-900 dark:text-white">Phone number</label>
                <input
                  value={formValues.ph}
                  onChange={e => {
                    setFormValues({ ...formValues, ph: e.currentTarget.value })
                    if (onSubmit) onSubmit(qrcode.generateVCardStr({ ...formValues, ph: e.currentTarget.value === '' ? undefined : e.currentTarget.value }))  
                  }}
                  type="tel" id="phone" className="bg-transparent border-transparent border-b-gray-300 focus:outline-transparent focus:border-transparent focus:border-b-orange-theme ring-transparent focus:ring-transparent outline-none focus:border-orange-theme w-full"  required />
              </div>
              <div>
                <label className="block mb-2 text-sm font-medium text-gray-900 dark:text-white">Website URL</label>
                <input
                  value={formValues.url}
                  onChange={e => {
                    setFormValues({ ...formValues, url: e.currentTarget.value })
                    if (onSubmit) onSubmit(qrcode.generateVCardStr({ ...formValues, url: e.currentTarget.value === '' ? undefined : e.currentTarget.value }))       
                  }}
                  type="url" id="website" className="bg-transparent border-transparent border-b-gray-300 focus:outline-transparent focus:border-transparent focus:border-b-orange-theme ring-transparent focus:ring-transparent outline-none focus:border-orange-theme w-full" required />
              </div>
              <div className="mb-6">
                <label className="block mb-2 text-sm font-medium text-gray-900 dark:text-white">Email address</label>
                <input
                  value={formValues.email}
                  onChange={e => {
                    setFormValues({ ...formValues, email: e.currentTarget.value })
                    if (onSubmit) onSubmit(qrcode.generateVCardStr({ ...formValues, email: e.currentTarget.value === '' ? undefined : e.currentTarget.value }))
                  }}
                  type="email" id="email" className="bg-transparent border-transparent border-b-gray-300 focus:outline-transparent focus:border-transparent focus:border-b-orange-theme ring-transparent focus:ring-transparent outline-none focus:border-orange-theme w-full" required />
              </div>
            </div>
          </form> */}

          <div className="absolute bottom-0 left-0 right-0 h-1 bg-blue-500 transform scale-x-0 transition-transform"></div>
        </div>
      </div>
    </div>
  )
}

export default QRFormContact