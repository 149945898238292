import { RangeSliderProps } from 'flowbite-react';
import  React, { useEffect } from 'react';
import { Range, getTrackBackground } from 'react-range';

type RangeSliderPropsType = {
    value:number,
    onChange:(val:number)=>void
    min:number,
    max:number
}

const RangeSlider:React.FC<RangeSliderPropsType> = ({value,onChange,max,min}) => {
    const [values, setValues] = React.useState([value])
    useEffect(() => {
    setValues([value])
    }, [value])
    
    return (
        <div className='w-full'>
            <Range
                step={0.1}
                min={min}
                max={max}
                values={values}
                onChange={(values) => {
                    
                    setValues(values)
                    onChange(values[0])
                }}
                renderTrack={({ props, children }) => (
                    <div
                        {...props}
                        style={{
                            ...props.style,
                            height: '6px',
                            width: '100%',
                            borderRadius: '10px',
                            background: getTrackBackground({
                                values: values,
                                colors: ["#fb923c", "#ccc"],
                                min: min,
                                max: max
                            }),
                        }}
                    >
                        {children}
                    </div>
                )
            }
                renderThumb={({ props }) => (
                    <div className='focus-visible:ring-transparent'
                        {...props}
                        style={{
                            ...props.style,
                            height: '26px',
                            width: '26px',
                            borderRadius:'50%',
                            backgroundColor: '#fb923c'
                        }}
                    />
                )}
            />

        </div>
    )
}

export default RangeSlider