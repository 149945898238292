import React from 'react'
import pdfcompressor from '../../assets/images/pdf2.png'
import DropdownButton from '../Buttons/SelectFileButton'
import Header3 from '../Header/Header3'
import Dropzone from 'react-dropzone'
import { useNavigate } from 'react-router'

const Intermediate3 = () => {
  const navigate = useNavigate()
  return (
    <div className="bg-orange-50 flex flex-col justify-center items-center">

      <Header3 />
      <div className='flex justify-center w-full py-10'>
        <h1 className='text-2xl font-semibold text-cyan-900'>PDF Compressor</h1>
      </div>
      <div className="flex justify-center items-center mx-4">
        <div className='relative bg-white font-semibold flex flex-col md:w-[45rem] sm:w-[35rem] w-[22rem] mt-4 mb-16 h-[28rem] shadow-2xl items-center justify-center px-4'>

          <div className="mb-8 flex items-center justify-center">
            <img src={pdfcompressor} alt="pdf comrpessor" className="w-10 h-10" />
          </div>
          <div className="text-center text-2xl text-cyan-900">
            Compress PDF
          </div>
          <div className="text-center text-lg mt-2 font-light text-cyan-900">
            Drag and drop a PDF to reduce its file size fast.
          </div>
          <div className='mt-2 text-cyan-900 absolute w-full h-full'>
            <Dropzone onDrop={acceptedFiles => {
              if(acceptedFiles !== null) navigate('/pdf-compressor/preview',{state:{files:acceptedFiles}})
            }}>
              {({ getRootProps, getInputProps }) => (
                <section>
                  <div {...getRootProps()}>
                    <input {...getInputProps()} accept='.pdf' />
                    <p className='absolute w-full h-full'></p>
                  </div>
                </section>
              )}
            </Dropzone>

          </div>
          <DropdownButton />
        </div>
      </div>
    </div>
  )
}

export default Intermediate3