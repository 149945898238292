import React, { useState } from 'react'
import qrcode, { QRContactDataType, QRSMSDataType } from '../../utils/qrcode'
import { Form, Formik } from 'formik'
import * as yup from 'yup'
import FormikInputField from '../Formik/FormikInputField'



const QRFormSms: React.FC<QRFormComponentProps> = ({ onSubmit }) => {
    const [formValues, setFormValues] = useState<QRSMSDataType>({ ph: '', message: '' })
    const phoneNoSchema = yup.string()
        .test('invalid-phone-no', 'Invalid Phone Number', (value) => value ? /^[\d\s]{8,17}$/.test(value) : true)
    const validationSchema = yup.object({ ph: phoneNoSchema })

    return (
        <div className="md:px-4 sm:px-0 px-0 dark:bg-gray-800">
            <div className='md:ml-6 sm:ml-0 ml-0 min-h-[20rem] sm:p-8 p-4 bg-white shadow-xl rounded-md'>
                <Formik onSubmit={(values, actions) => { }} validateOnChange={true} initialValues={formValues} validationSchema={validationSchema}>
                    {(actions) => {
                        return (
                            <Form>
                                <div className="grid gap-6 mb-6">

                                    <FormikInputField
                                        labelColour='text-orange-400'
                                        inputStyle='py-1 px-0'
                                        onChange={e => {
                                            actions.setFieldValue('ph', e.target.value)
                                            setFormValues({ ...formValues, ph: e.currentTarget.value })
                                            if (onSubmit) onSubmit(qrcode.generateVCardStr({ ...formValues, ph: e.currentTarget.value === '' ? undefined : e.currentTarget.value }))
                                        }}
                                        label="ph"
                                        placeholder=""
                                        name='Enter Phone Number' />

                                </div>
                            </Form>
                        )
                    }}
                </Formik>
                {/* <p className='text-orange-theme font-medium text-sm'>Enter Phone Number</p>
                <div className="relative">
                    <input 
                        value={formValues.ph} 
                        onChange={e => {
                            if(onSubmit) onSubmit(qrcode.generateSMSStr({...formValues, ph: e.currentTarget.value === '' ? undefined : e.currentTarget.value}))
                            setFormValues({...formValues, ph: e.currentTarget.value})
                        }} 
                        type="text" title='a' className="px-0 bg-transparent border-transparent border-b-gray-300 focus:outline-transparent focus:border-transparent focus:border-b-orange-theme ring-transparent focus:ring-transparent outline-none focus:border-orange-theme w-full" />
                    <div className="absolute bottom-0 left-0 right-0 h-1 bg-blue-500 transform scale-x-0 transition-transform"></div>
                </div> */}

                <div className="relative">
                    <textarea
                        value={formValues.message}
                        onChange={e => {
                            if (onSubmit) onSubmit(qrcode.generateSMSStr({ ...formValues, message: e.currentTarget.value === '' ? undefined : e.currentTarget.value }))
                            setFormValues({ ...formValues, message: e.currentTarget.value })
                        }}
                        id="message" rows={8} className=" mt-6 block p-2.5 w-full text-sm text-gray-900 bg-white rounded-lg border border-gray-300 focus:ring-transparent focus:border-gray-900 min-h-20" placeholder="Write your Message here..."></textarea>
                    <div className="absolute bottom-0 left-0 right-0 h-1 bg-blue-500 transform scale-x-0 transition-transform"></div>
                </div>
            </div>
        </div>
    )
}

export default QRFormSms