import React, { ReactNode, useEffect, useState } from 'react'
import CopyIcon from '../../assets/images/CopyIcon.png';
import DoneIcon from '../../assets/images/done.svg'

type CopyButtonPropsType = {
  onClick: React.MouseEventHandler<HTMLButtonElement>
  icon:ReactNode
  text:string
}

const CopyButton: React.FC<CopyButtonPropsType> = ({onClick,icon,text}) => {
  const [showCopied, setShowCopied] = useState(false)

  useEffect(() => {
    if(showCopied){
      setTimeout(() => {
        setShowCopied(false)
      }, 1000)
    }
  }, [showCopied])

  return (
    <div className='mr-6'>
        <button type='submit' onClick={(e) => {
          setShowCopied(true)
          onClick(e)
        }} className='relative pass-copy-btn'>
        {icon}
        {showCopied && (
          <div className="flex items-center rounded-md absolute w-fit -top-14 -right-10 bg-green-400  px-4 py-3 ">
          <p className='text-sm pr-4 text-white'>{text}</p>
          <img src={DoneIcon} alt="copydone" className='bg-white rounded-xl w-4 h-4'/>
        </div>
        )}
        </button>
    </div>
  )
}

export default CopyButton
