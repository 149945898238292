import React, { useEffect, useState } from 'react'
import Header3 from '../Header/Header3';
import RangeSlider from '../RangeSliders/RangeSlider';
import CopyButton from '../Buttons/CopyButton';
import CopyIcon from '../../assets/images/CopyIcon.png'
import { SketchPicker } from 'react-color';
import { MdOutlineContentCopy } from 'react-icons/md'

type BoxShadowStateType = {
  lengthX: number | '',
  lengthY: number,
  blurRadius: number,
  spreadRadius: number,
  shadowColor: {
    hex: string,
    r?: number,
    g?: number,
    b?: number
  },
  bgColor: string,
  boxColor: string,
  opacity: number,
  isInset: boolean
}
const getlimitedNumber = (limit: number, input: any) => {
  const intInput = parseInt(input.toString())
  if (intInput > limit && intInput > 0) return limit
  if (intInput < 0 && intInput < -limit) return -limit
  return input
}

const generateBoxShadowCode = (boxShadow: BoxShadowStateType) => {
  return `
        -webkit-box-shadow: ${boxShadow.isInset ? 'inset' : ''}${boxShadow.lengthX}px ${boxShadow.lengthY}px ${boxShadow.blurRadius}px ${boxShadow.spreadRadius}px rgba(0,0,0,${boxShadow.opacity}); \n
        -moz-box-shadow: ${boxShadow.isInset ? 'inset' : ''}${boxShadow.lengthX}px ${boxShadow.lengthY}px ${boxShadow.blurRadius}px ${boxShadow.spreadRadius}px rgba(0,0,0,${boxShadow.opacity}); \n
        box-shadow: ${boxShadow.isInset ? 'inset' : ''} ${boxShadow.lengthX}px  ${boxShadow.lengthY}px  ${boxShadow.blurRadius}px  ${boxShadow.spreadRadius}px rgba(0,0,0,${boxShadow.opacity});   
            `
}

const Intermediate6 = () => {
  const [colorPickerStatus, setColorPickerStatus] = useState<'bgColor' | 'shadowColor' | 'boxColor' | ''>()

  const [boxShadowState, setBoxShadowState] = useState<BoxShadowStateType>({
    lengthX: 0,
    lengthY: 0,
    spreadRadius: 0,
    blurRadius: 0,
    shadowColor: {
      hex: '#000000',
      r: 0,
      g: 0,
      b: 0
    },
    bgColor: '#FFFFFF',
    boxColor: '#FB923C',
    opacity: 1,
    isInset: false
  })
  const [boxShadowCode, setBoxShadowCode] = useState(
    generateBoxShadowCode(boxShadowState)
  )

  useEffect(() => {
    setBoxShadowCode(generateBoxShadowCode(boxShadowState))
  }, [boxShadowState])

  return (
    <div
      className="bg-orange-50 flex flex-col justify-center items-center relative">
      <div
        onClick={() => setColorPickerStatus('')}
        className=' absolute top-0 left-0 w-full h-full '></div>
      <Header3 />
      <div className='flex justify-center w-full pt-10 pb-0'>
        <h1 className='text-2xl font-semibold text-cyan-900'>Box Shadow Generator</h1>
      </div>
      <div className="container mx-auto flex justify-center items-center">
        <div className='bg-white font-semibold flex items-center flex-row 2xl:w-11/12 lg:w-11/12 md:w-12/12 h-full shadow-2xl py-12 px-12 my-16 md:flex-nowrap sm:flex-wrap flex-wrap flex-wra justify-between'>

          <div className="xl:w-5/12 md:w-6/12 sm:w-6/12 w-full h-full bg-white mb-8 flex md:order-1 sm:order-2 order-2">
            <div className='w-full'>

              <div className='box-shadow-info-side-wrap mb-2 w-full text-black'>

                {/* Horizontal Length */}
                <div className='w-full box-shadow-info-side-each mb-2'>
                  {/* Row 1 */}
                  <div className='flex justify-between items-center mb-2'>
                    <p className='md:text-lg sm:text-md text-md font-normal'>Horizontal Length</p>
                    <div className='flex'>
                      <input max={30} value={boxShadowState.lengthX} onChange={(e) => setBoxShadowState((prev) => ({ ...prev, lengthX: getlimitedNumber(30, e.target.value) }))} type="number" className="z-10 w-14 h-10 p-2 text-gray-700 border border-gray-300 rounded-md focus:outline-none " />
                      {/* <input type="text" /> */}
                      <p className='ml-2 leading-loose md:text-lg sm:text-md text-md font-normal'>px</p>
                    </div>
                  </div>

                  {/* Row 2 */}
                  <div className='my-4'>
                    <RangeSlider value={boxShadowState.lengthX === '' ? 0 : boxShadowState.lengthX} onChange={value => { setBoxShadowState({ ...boxShadowState, lengthX: parseInt(value.toString()) }) }} min={-30} max={30} />
                  </div>
                </div>
                {/* Vertical Length */}
                <div className='w-full box-shadow-info-side-each mb-2'>
                  {/* Row 1 */}
                  <div className='flex justify-between items-center mb-2'>
                    <p className='md:text-lg sm:text-md text-md font-normal'>Vertical Length</p>
                    <div className='flex'>
                      <input value={boxShadowState.lengthY} onChange={(e) => setBoxShadowState((prev) => ({ ...prev, lengthY: getlimitedNumber(30, e.target.value) }))} aria-label='a' type="number" name="my-input" className=" z-10 w-14 h-10 p-2  text-gray-700 border border-gray-300 rounded-md focus:outline-none " />
                      <p className='ml-2 leading-loose md:text-lg sm:text-md text-md font-normal'>px</p>
                    </div>
                  </div>

                  {/* Row 2 */}
                  <div className='my-4'>
                    <RangeSlider value={boxShadowState.lengthY} onChange={value => { setBoxShadowState({ ...boxShadowState, lengthY: parseInt(value.toString()) }) }} min={-30} max={30} />
                  </div>
                </div>
                {/* Line */}
                <div className='line-wrap w-full h-[2px] bg-slate-100 mb-2'></div>
                {/* Blur Radius */}
                <div className='w-full box-shadow-info-side-each mb-2'>
                  {/* Row 1 */}
                  <div className='flex justify-between items-center mb-2'>
                    <p className='md:text-lg sm:text-md text-md font-normal'>Blur Radius</p>
                    <div className='flex'>
                      <input value={boxShadowState.blurRadius} onChange={(e) => setBoxShadowState((prev) => ({ ...prev, blurRadius: getlimitedNumber(200, e.target.value) }))} aria-label='a' type="number" id="" name="my-input" className="z-10 w-14 h-10 p-2  text-gray-700 border border-gray-300 rounded-md focus:outline-none " />
                      <p className='ml-2 leading-loose md:text-lg sm:text-md text-md font-normal'>px</p>
                    </div>
                  </div>

                  {/* Row 2 */}
                  <div className='my-4'>
                    <RangeSlider value={boxShadowState.blurRadius} onChange={value => { setBoxShadowState((prev) => ({ ...prev, blurRadius: parseInt(value.toString()) })) }} min={0} max={300} />
                  </div>
                </div>
                {/* Spread Radius */}
                <div className='w-full box-shadow-info-side-each mb-2'>
                  {/* Row 1 */}
                  <div className='flex justify-between items-center mb-2'>
                    <p className='md:text-lg sm:text-md text-md font-normal'>Spread Radius</p>
                    <div className='flex'>
                      <input value={boxShadowState.spreadRadius} onChange={(e) => setBoxShadowState((prev) => ({ ...prev, spreadRadius: getlimitedNumber(40, e.target.value) }))} aria-label='a' type="number" id="my-input-1" name="my-input" className="z-10 w-14 h-10 p-2  text-gray-700 border border-gray-300 rounded-md focus:outline-none " />
                      <p className='ml-2 leading-loose md:text-lg sm:text-md text-md font-normal'>px</p>
                    </div>
                  </div>

                  {/* Row 2 */}
                  <div className='my-4'>
                    <RangeSlider value={boxShadowState.spreadRadius} onChange={value => { setBoxShadowState((prev) => ({ ...prev, spreadRadius: parseInt(value.toString()) })) }} min={-40} max={40} />
                  </div>
                </div>
                {/* Line */}
                <div className='line-wrap w-full h-[2px] bg-slate-100 mb-2'></div>
                {/* Shadow Colour */}
                <div className='w-full box-shadow-info-side-each flex justify-between mb-2'>
                  <p className='md:text-lg sm:text-md text-md font-normal'>Shadow Colour</p>
                  <label htmlFor="" className='flex relative'>
                    <div
                      onClick={() => {
                        setColorPickerStatus('shadowColor')
                      }}
                      style={{ backgroundColor: boxShadowState.shadowColor.hex }} className='w-12 h-10 rounded border cursor-pointer'></div>
                    <div className='absolute bottom-[50px] z-10'>
                      {colorPickerStatus === 'shadowColor' && <SketchPicker color={boxShadowState.shadowColor.hex} onChange={
                        (color) => {
                          var result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(color.hex);
                          setBoxShadowState({
                            ...boxShadowState,
                            shadowColor: {
                              hex: color.hex,
                              r: result ? parseInt(result[1], 16) : 0,
                              g: result ? parseInt(result[2], 16) : 0,
                              b: result ? parseInt(result[3], 16) : 0,
                            }
                          })
                        }
                      } />}
                    </div>
                    {/* <input
                      value={boxShadowState.shadowColor.hex}
                      onChange={(e) => {
                        var result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(e.target.value);
                        setBoxShadowState({
                          ...boxShadowState,
                          shadowColor: {
                            hex: e.target.value,
                            r: result ? parseInt(result[1], 16) : 0,
                            g: result ? parseInt(result[2], 16) : 0,
                            b: result ? parseInt(result[3], 16) : 0,
                          }
                        })
                      }} aria-label='a' type="color" id="shadow-color" name="my-input" className=" invisible w-[0px] rounded-md text-gray-700 " /> */}
                    <input
                      onChange={(e) => {
                        var result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(e.target.value);
                        setBoxShadowState({
                          ...boxShadowState,
                          shadowColor: {
                            hex: e.target.value,
                            r: result ? parseInt(result[1], 16) : 0,
                            g: result ? parseInt(result[2], 16) : 0,
                            b: result ? parseInt(result[3], 16) : 0,
                          }
                        })
                      }}
                      value={boxShadowState.shadowColor.hex} aria-label='a' type="text" id="" name="" className="w-[100px] h-10 ml-4 p-2 text-gray-700 border border-gray-300 rounded-md  outline-transparent focus:border-gray-900 focus:ring-gray-300 focus:outline-transparent " />

                  </label>
                </div>

                {/* Line */}
                <div className='line-wrap w-full h-[2px] bg-slate-100 mb-2'></div>

                {/* Background Colour */}
                <div className='w-full box-shadow-info-side-each flex justify-between items-center mb-2'>
                  <p className='md:text-lg sm:text-md text-md font-normal'>Background Colour</p>
                  <label className='flex relative'>
                    <div
                      onClick={() => setColorPickerStatus('bgColor')}
                      style={{ backgroundColor: boxShadowState.bgColor }} className='w-12 h-10 rounded border cursor-pointer'></div>
                    <div className='absolute bottom-[50px] z-10'>
                      {colorPickerStatus === 'bgColor' && <SketchPicker
                        color={boxShadowState.bgColor}
                        onChange={(color) => {
                          setBoxShadowState({ ...boxShadowState, bgColor: color.hex })
                        }}
                      />}
                    </div>
                    {/* <input
                      value={boxShadowState.bgColor}
                      onChange={(e) => {
                        setBoxShadowState({ ...boxShadowState, bgColor: e.target.value })
                      }} aria-label='a' type="color" id="bg-color" name="my-input" className=" invisible w-[0px] rounded-md text-gray-700 " /> */}
                    <input
                      onChange={(e) => setBoxShadowState({ ...boxShadowState, bgColor: e.target.value })}
                      value={boxShadowState.bgColor} aria-label='a' type="text" id="" name="" className="w-[100px] h-10 ml-4 p-2 text-gray-700 border border-gray-300 rounded-md  outline-transparent focus:border-gray-900 focus:ring-gray-300 focus:outline-transparent " />

                  </label>
                </div>

                {/* Line */}
                <div className='line-wrap w-full h-[2px] bg-slate-100 mb-2'></div>

                {/* Box Colour */}
                <div className='w-full box-shadow-info-side-each flex justify-between items-center mb-2'>
                  <p className='md:text-lg sm:text-md text-md font-normal'>Box Colour</p>
                  <label className='flex relative'>
                    <div
                      onClick={() => setColorPickerStatus('boxColor')}
                      style={{ backgroundColor: boxShadowState.boxColor }} className='w-12 h-10 rounded border cursor-pointer'></div>
                    <div className='absolute bottom-[50px] z-10'>
                      {colorPickerStatus === 'boxColor' && <SketchPicker color={boxShadowState.boxColor}
                        onChange={(color) => {
                          setBoxShadowState({ ...boxShadowState, boxColor: color.hex })
                        }}
                      />}
                    </div>

                    <input
                      onChange={(e) => setBoxShadowState({ ...boxShadowState, boxColor: e.target.value })}
                      value={boxShadowState.boxColor} aria-label='a' type="text" id="" name="" className="w-[100px] h-10 ml-4 p-2 text-gray-700 border border-gray-300 rounded-md  outline-transparent focus:border-gray-900 focus:ring-gray-300 focus:outline-transparent " />

                  </label>
                </div>

                {/* Line */}
                <div className='line-wrap w-full h-[2px] bg-slate-100 mb-2'></div>

                {/* Opacity*/}
                <div className='w-full box-shadow-info-side-each mb-2'>
                  {/* Row 1 */}
                  <div className='flex justify-between items-center mb-2'>
                    <p className='md:text-lg sm:text-md text-md font-normal'>Opacity</p>
                    <div className='flex'>
                      <input min={0} value={boxShadowState.opacity} onChange={(e) => parseInt(e.target.value) >= 0 && setBoxShadowState((prev) => ({ ...prev, opacity: getlimitedNumber(1, e.target.value) }))} aria-label='a' type="number" id="my-input-1" name="my-input" className="z-10 w-12 h-10 p-2  text-gray-700 border border-gray-300 rounded-md focus:outline-none " />

                    </div>
                  </div>

                  {/* Row 2 */}
                  <div className='my-4'>
                    <RangeSlider value={boxShadowState.opacity} onChange={value => setBoxShadowState((prev) => ({ ...prev, opacity: value }))} min={0} max={1} />
                  </div>
                </div>

                {/* Line */}
                <div className='line-wrap w-full h-[2px] bg-slate-100 mb-2'></div>

                <div className='flex flex-row mt-4 w-full items-center justify-end'>
                  <span className="mr-3 text-md font-normal text-cyan-900 dark:text-gray-300">Outline</span>
                  <label className="relative inline-flex items-center mr-5 cursor-pointer">
                    <input type="checkbox" checked={boxShadowState.isInset} onChange={(e) => setBoxShadowState((prev) => ({ ...prev, isInset: e.target.checked }))} className="sr-only peer" />
                    <div className="w-11 h-6 bg-gray-200 rounded-full peer dark:bg-gray-700 peer-focus:ring-4 peer-focus:ring-transparent dark:peer-focus:ring-yellow-800 peer-checked:after:translate-x-full peer-checked:after:border-white after:content-[''] after:absolute after:top-0.5 after:left-[2px] after:bg-white after:border-gray-300 after:border after:rounded-full after:h-5 after:w-5 after:transition-all dark:border-gray-600 peer-checked:bg-orange-theme"></div>
                    <span className="ml-3 text-md font-normal text-cyan-900 dark:text-gray-300">Inset</span>
                  </label>
                </div>


              </div>
            </div>
          </div>

          <div style={{ backgroundColor: boxShadowState.bgColor }} className="ml-0 sm:ml-3 xl:w-7/12 md:w-6/12 sm:w-6/12 w-full h-full  mb-8 flex items-center justify-center md:order-2 sm:order-1 order-1">
            <div style={{
              backgroundColor: boxShadowState.boxColor,
              // boxShadow: '10px 10px'
              boxShadow: `
              ${boxShadowState.isInset ? 'inset' : ''}
              ${boxShadowState.lengthX}px 
              ${boxShadowState.lengthY}px 
              ${boxShadowState.blurRadius}px 
              ${boxShadowState.spreadRadius}px
              rgba(
                ${boxShadowState.shadowColor.r},
                ${boxShadowState.shadowColor.g},
                ${boxShadowState.shadowColor.b},
                ${boxShadowState.opacity}
                )
              `
            }} className=' relative w-full md:h-[25rem] sm:h-[18rem] h-[18rem] bg-orange-theme flex justify-center flex-col items-center px-14 lg:mx-14 md:mx-0'>
              <div className='relative text-sm flex flex-col items-center gap-2 bg-white md:h-[15rem] sm:h-[10rem] h-[12rem] w-full m-12 md:py-12 sm:py-6 py-6 md:px-8 sm:px-4 px-4 outline-none font-normal overflow-y-scroll md:overflow-visible scrollbar-hidden'>

                <p>
                  {`-webkit-box-shadow: 
                ${boxShadowState.isInset ? 'inset' : ''}
                ${boxShadowState.lengthX}px 
                ${boxShadowState.lengthY}px 
                ${boxShadowState.blurRadius}px 
                ${boxShadowState.spreadRadius}px 
                rgba(0,0,0,${boxShadowState.opacity});`}
                </p>
                <p>
                  {`-moz-box-shadow:
                ${boxShadowState.isInset ? 'inset' : ''}
                ${boxShadowState.lengthX}px 
                ${boxShadowState.lengthY}px 
                ${boxShadowState.blurRadius}px 
                ${boxShadowState.spreadRadius}px 
                rgba(0,0,0,${boxShadowState.opacity});`}
                </p>
                <p>
                  {`box-shadow:
                ${boxShadowState.isInset ? 'inset' : ''}
                ${boxShadowState.lengthX}px 
                ${boxShadowState.lengthY}px 
                ${boxShadowState.blurRadius}px 
                ${boxShadowState.spreadRadius}px 
                rgba(0,0,0,${boxShadowState.opacity});`}
                </p>

              </div>
              <div className='ml-6 mb-12'>
                {/* <CopyButton
                    icon={<MdOutlineContentCopy size={20} />}
                    onClick={async _ => {
                      navigator.clipboard.writeText(boxShadowCode)
                    }} /> */}
                <CopyButton
                text=' Code Copied'
                  // icon={<img src={CopyIcon}  alt="MyLogo" className="w-6 h-6 " />}
                  icon={
                    <div className='bg-black w-[150px] h-[50px] rounded-md flex justify-center items-center'>
                      <p className='text-white'>Copy</p>
                    </div>
                  }
                  onClick={async _ => {
                    navigator.clipboard.writeText(boxShadowCode)
                  }} />
              </div>

            </div>
          </div>

        </div>
      </div>
    </div>
  )
}

export default Intermediate6



