import React from 'react'
import Header from '../components/Header/Header'
import Intermediate4 from '../components/Intermediate/Intermediate4'
import Footer from '../components/Footer/Footer'
import ReactHelmet from '../components/ReactHelmet'

const PdfCompressor1 = () => {
  return (
    <div className='flex flex-col justify-between min-h-[100vh]'>
    <React.Fragment>
     <ReactHelmet title='Free PDF Compressor - Compress PDF Online' description='Reduce PDF file size effortlessly with our efficient PDF compressor tool. Compress large PDFs while maintaining quality, making them easy to share, store, and upload. "'/>
      <Header />
      <Intermediate4 />
      <Footer />
    </React.Fragment>
    </div>
  )
}

export default PdfCompressor1