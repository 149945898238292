import React from 'react'
import pdfcompressor from '../../assets/images/pdfcompressor.png'
import qrcodegenerator from '../../assets/images/qrcodecompressor.png'
import passwordgenerator from '../../assets/images/passwordgenerator.png'
import boxshadowgenerator from '../../assets/images/boxshadowgenerator.png'
import screenreso from '../../assets/images/screen-resolution-checker.png'
import { Link } from 'react-router-dom'

const Intermediate2 = () => {
  return (
    <div className="container mx-auto py-20">

      <div className="flex 2xl:flex-nowrap sm:flex-nowrap flex-wrap justify-between w-full 2xl:px-0 sm:px-0 px-12">

        <Link to="/pdf-compressor" className='2xl:w-[23%] sm:w-[23%] w-[100%] 2xl:mb-0 sm:mb-0 mb-10'>
          <div className="tool-tile hover:bg-orange-theme font-semibold hover:text-white flex-col w-full shadow-xl rounded-md py-10 flex items-center justify-center transition ease-in-out delay-50 duration-300">
            <div className="bg-cyan-100 ml-1 mb-6 w-24 h-24 rounded-full flex items-center justify-center">
              <img src={pdfcompressor} alt="pdf compressor" className="w-10" />
            </div>
            <div className="text-center font-normal text-xl pt-4">
              PDF <br></br> Compressor
            </div>
            <span className='hoverline'></span>
          </div>
        </Link>
        <Link to="/qr-code-generator" className='2xl:w-[23%] sm:w-[23%] w-[100%] 2xl:mb-0 sm:mb-0 mb-10'>
          <div className="tool-tile hover:bg-orange-theme font-semibold hover:text-white  flex-col w-full shadow-xl rounded-md py-10 flex items-center justify-center transition ease-in-out delay-50 duration-300">
            <div className="bg-sky-100 ml-1 mb-6 w-24 h-24 rounded-full flex items-center justify-center">
              <img src={qrcodegenerator} alt="qr code generator" className="w-10" />
            </div>
            <div className="text-center font-normal text-xl pt-4">
              QR Code <br></br> Generator
            </div>
            <span className='hoverline'></span>
          </div>
        </Link>
        <Link to="/strong-password-generator" className='2xl:w-[23%] sm:w-[23%] w-[100%] 2xl:mb-0 sm:mb-0 mb-10'>
          <div className="tool-tile hover:bg-orange-theme font-semibold hover:text-white  flex-col w-full shadow-xl rounded-md py-10 flex items-center justify-center transition ease-in-out delay-50 duration-300">
            <div className="bg-rose-100 ml-1 mb-6 w-24 h-24 rounded-full flex items-center justify-center">
              <img src={passwordgenerator} alt="password generator" className="w-10" />
            </div>
            <div className="text-center font-normal text-xl pt-4">
              Strong Password <br></br>Generator
            </div>
            <span className='hoverline'></span>
          </div>
        </Link>
        <Link to="/css-box-shadow-generator" className='2xl:w-[23%] sm:w-[23%] w-[100%] 2xl:mb-0 sm:mb-0 mb-10'>
          <div className="tool-tile hover:bg-orange-theme font-semibold hover:text-white  flex-col w-full shadow-xl rounded-md py-10 flex items-center justify-center transition ease-in-out delay-50 duration-300">
            <div className="bg-rose-50 ml-1 mb-6 w-24 h-24 rounded-full flex items-center justify-center">
              <img src={boxshadowgenerator} alt="box shadow generator" className="w-10" />
            </div>
            <div className="text-center font-normal text-xl pt-4">
              Box Shadow<br></br>Generator
            </div>
            <span className='hoverline'></span>
          </div>
        </Link>
      </div>

      <div className="flex 2xl:flex-nowrap sm:flex-nowrap flex-wrap justify-between w-full 2xl:px-0 sm:px-0 px-12">
      <Link to="/screen-resolution-checker" className='2xl:w-[23%] sm:w-[23%] w-[100%] 2xl:mb-0 sm:mb-0 mb-10'>
          <div className="tool-tile hover:bg-orange-theme font-semibold hover:text-white  flex-col w-full shadow-xl rounded-md py-10 flex items-center justify-center transition ease-in-out delay-50 duration-300">
            <div className="bg-rose-50 ml-1 mb-6 w-24 h-24 rounded-full flex items-center justify-center">
              <img src={screenreso} alt="what is my screen resolution" className="w-10" />
            </div>
            <div className="text-center font-normal text-xl pt-4">
              Screen Resolution<br></br>Checker
            </div>
            <span className='hoverline'></span>
          </div>
        </Link>
      </div>
    </div>
  )
}

export default Intermediate2
