import React from 'react';
import './App.css';
import WelcomePage from './containers/WelcomePage';
import { BrowserRouter, Routes, Route} from "react-router-dom";
import PdfCompressor from './containers/PdfCompressor';
import PdfCompressor1 from './containers/PdfCompressor1';
import QRCodeGenerator from './containers/QRCodeGenerator';
import PasswordGenerator from './containers/PasswordGenerator';
import ScreenresolutionChecker from './containers/ScreenresolutionChecker';
import BoxShadowGenerator from './containers/BoxShadowGenerator';
import PrivacyPolicy from './containers/PrivacyPolicy';
import { useEffect } from "react";
import { useLocation } from "react-router-dom";
import PdfCompressor3 from './containers/PdfCompressor3';

export function ScrollToTop() {
  const { pathname } = useLocation();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);

  return null;
}

function App() {
  return (
      // <BrowserRouter>
      //     <Routes>
      //       <Route  path="" element={<WelcomePage />} />
      //       <Route  path="/WelcomePage" element={<WelcomePage />} />
      //       <Route  path="/PdfCompressor" element={<PdfCompressor />} />
      //       <Route  path="/PdfCompressor1" element={<PdfCompressor1 />} />
      //       <Route  path="/QRCodeGenerator" element={<QRCodeGenerator />} />
      //       <Route  path="/PasswordGenerator" element={<PasswordGenerator />} />
      //       <Route  path="/BoxShadowGenerator" element={<BoxShadowGenerator />} />
      //     </Routes>
      // </BrowserRouter>
      

    <BrowserRouter>
      <ScrollToTop />
      <Routes>
        <Route path="" element={<WelcomePage />} />
        <Route path="/welcomePage" element={<WelcomePage />} />
        <Route path="/pdf-compressor" element={<PdfCompressor />} />
        <Route path="/pdf-compressor/preview" element={<PdfCompressor1 />} />
        <Route path="/pdf-compressor/download" element={<PdfCompressor3 />} />
        <Route path="/qr-code-generator" element={<QRCodeGenerator />} />
        <Route path="/strong-password-generator" element={<PasswordGenerator />} />
        <Route path="/css-box-shadow-generator" element={<BoxShadowGenerator />} />
        <Route path="/screen-resolution-checker" element={<ScreenresolutionChecker />} />
        <Route path="/privacy-policy" element={<PrivacyPolicy />} />
      </Routes>
    </BrowserRouter>
  );
}

export default App;
