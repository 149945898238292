import QRCodeStyling from 'qr-code-styling';
import React, { useEffect, useMemo, useRef, useState } from 'react'
import { QRStyleComponentProps } from './types';

// const qrCode = new QRCodeStyling({
//   data: '',
//   type: 'canvas',
//   width: 300,
//   height: 300,
//   // dotsOptions: {
//   //   color: "#4267b2",
//   //   type: "rounded"
//   // },
//   // imageOptions: {
//   //   crossOrigin: "anonymous",
//   //   margin: 20
//   // }
// });



const StyledQR: React.FC<QRStyleComponentProps> = ({ options }) => {
  const ref = useRef<HTMLDivElement>(null);
  const [appended, setAppeneded] = useState(false)
  const qrCode = useMemo(() => new QRCodeStyling({
    data: '',
    type: 'canvas',
    width: 900,
    height: 900,
    margin:30
  
  }), [])

  useEffect(() => {
    qrCode.update(options)
  }, [qrCode, options])

  useEffect(() => {
    if (!appended && ref.current != null) {
      qrCode.append(ref.current);
      setAppeneded(true)
    }
  }, [qrCode, ref, appended]);

  return (
    <div className='qr-img-wrap md:w-full sm:w-6/12 w-6/12 relative overflow-visible flex justify-center md:m-2 sm:m-0 m-0 xl:px-10 md:px-6 sm:px-4 px-4 md:py-4 sm:py-6 py-6  hover:border-8 hover:border-gray-200 cursor-pointer border-8 border-slate-50 duration-300'>
      <div ref={ref} />

      {/* <QRCode id={id} value={qrText} size={1000} style={{width: '100%'}}/> */}
      {/* <img src={qr1} alt="MyImage" className="md:w-full sm:w-4/12 w-4/12 2xl:h-full sm:h-4/12 h-4/12" /> */}
      {options?.data != '' && <button title='qrcodedownload' onClick={() => qrCode.download()}><svg xmlns="http://www.w3.org/2000/svg" className='cursor-pointer border-white qr-dwnld-btn absolute md:top-2 sm:top-8 top-8 right-8 bg-orange-theme hover:bg-orange-400 duration-300 p-2 w-10 h-10 rounded-md' width="32" height="32" viewBox="0 0 24 24"><path fill="white" d="M6.5 20q-2.275 0-3.888-1.575T1 14.575q0-1.95 1.175-3.475T5.25 9.15q.575-2.025 2.138-3.4T11 4.075v8.075L9.4 10.6L8 12l4 4l4-4l-1.4-1.4l-1.6 1.55V4.075q2.575.35 4.288 2.313T19 11q1.725.2 2.863 1.488T23 15.5q0 1.875-1.313 3.188T18.5 20h-12Z" /></svg></button>}
    </div>
  )
}

export default StyledQR