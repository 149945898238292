import React from 'react'
import Header1 from '../components/Header/Header'
import Footer from '../components/Footer/Footer'
import Intermediate6 from '../components/Intermediate/Intermediate6'
import ReactHelmet from '../components/ReactHelmet'

const BoxShadowGenerator = () => {
  return (
    <React.Fragment>
      <ReactHelmet title='Box Shadow Generator - Generate CSS Box Shadow Online' description="Generate custom box shadows for your website with this easy-to-use box shadow generator. Experiment with different shadow styles, colors, and dimensions to create stunning visual effects for your elements."/>
      <Header1 />
      <Intermediate6 />
      <Footer />
    </React.Fragment>
  )
}

export default BoxShadowGenerator