import React from 'react'
import { Helmet } from 'react-helmet'
type ReactHelmetTYpeProps = {
    title: string,
    description: string
}
const ReactHelmet: React.FC<ReactHelmetTYpeProps> = ({ title, description }) => {
    return (
        <Helmet>
            <title>{title}</title>
            <meta name="description" content={description} />
            <meta property="og:title" content={title} />
            <meta property="og:description" content={description} />
            {/* Add more meta tags as needed */}
        </Helmet>)
}

export default ReactHelmet