import React from 'react'
import Header from '../components/Header/Header'
import ScreenResolutionChecker from '../components/Intermediate/ScreenResolutionChecker'
import Footer from '../components/Footer/Footer'
import ReactHelmet from '../components/ReactHelmet'

const ScreenresChecker = () => {
  return (
    <React.Fragment>
      <ReactHelmet title='What is My Screen Resolution - Check Screen Resolution' description='Check your device screen resolution online using our free screen resolution checker tool.'/> 
      <Header />
      <ScreenResolutionChecker />
      <Footer />
    </React.Fragment>
  )
}

export default ScreenresChecker