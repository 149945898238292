
const uppercaseChars = 'ABCDEFGHIJKLMNOPQRSTUVWXYZ';
const lowercaseChars = 'abcdefghijklmnopqrstuvwxyz';
const numberChars = '0123456789';
const symbolChars = '!@#$%^&*()-_+=[]{}|:;"<>,.?/~`';


export type PasswordGenOptionsType = {
    uppercase: boolean, lowercase: boolean, numbers: boolean, symbols: boolean,
}

const generate = (options: PasswordGenOptionsType, length: number) => {
    let chars = ''
    if(options.uppercase) chars += uppercaseChars
    if(options.lowercase) chars += lowercaseChars
    if(options.numbers) chars += numberChars
    if(options.symbols) chars += symbolChars
    if(chars.length > 0 && length > 0){
        let password = ''
        for(let i=0; i<length; i++){
            password += chars[Math.round(Math.random() * (chars.length - 1))]
        }
        return password
    }
    return ''
}

const passwordGen = {
    generate
}

export default passwordGen                                                       