import { FileQuality } from "../../components/Intermediate/Intermediate4";
import { apiInstance } from "../client";
import { UploadProgessFnType } from "./types";

const download = async (url:string) =>  apiInstance.get(url,{responseType:'arraybuffer'})

const compress =  (files:File[],quality:FileQuality,progressFn:UploadProgessFnType) => {
    const formData = new FormData()
    for (const file of files){
        formData.append('pdf',file)

    }
    formData.append('quality',quality)
    return apiInstance.post<PdfCompressType>('/compress',formData,{headers: { 'Content-Type': 'multipart/form-data' } ,onUploadProgress:progressFn})
}

const pdfCompressApi = {download,compress}

export default pdfCompressApi