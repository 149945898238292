import { Dropdown, Navbar } from 'flowbite-react'
import React, { useEffect, useState } from 'react'
// import CloseIcon from '/assets/svg/close.svg'
import { IoIosArrowDown,IoIosArrowDropright, IoIosArrowForward, IoIosArrowRoundForward } from 'react-icons/io'
import { GrClose } from 'react-icons/gr'
import { useNavigate } from 'react-router'
// export type MobMenuContentProps = {
//    onClose: (flag:boolean)=>void
// }
const MobMenuContent = () => {
   const navigate = useNavigate()
   const [dropdownStatus, setDropdownStatus] = useState(false)
  
   
   return (

         <div className='  px-4 mob-sidebar flex-col gap-6 bg-white w-[80vw] h-[100vh] absolute right-0 top-[-70px] pt-20 shadow-lg'>
            {/* <div 
            onClick={()=>onClose(false)}
            className='absolute top-5 right-5 ' >
               <GrClose color='black' />
            </div> */}
            <div className='mob-sidebar-item'>
               <a
                  href="/"
                  className="2xl:py-0 sm:py-0 py-2 px-6 tracking-wide text-[16px] font-medium duration-300 text-gray-500 hover:text-black hover:underline hover:decoration-orange-theme decoration-white underline-offset-8"
               >
                  HOME
               </a>
            </div>
            {/* <Navbar.Link href="/navbars"
            className="hover:text-orange-theme 2xl:py-0 sm:py-0 py-2 px-6 tracking-wide text-[16px] font-medium duration-300 text-gray-500 hover:text-black hover:underline hover:decoration-orange-theme decoration-white underline-offset-8">
            ABOUT
          </Navbar.Link> */}
            <div className='px-4'>
               <hr className='border-black border-light my-2'></hr>
            </div>
            <div >
               <div className=' mob-sidebar-item flex items-center justify-between pr-3 cursor-pointer ' onClick={() => setDropdownStatus(!dropdownStatus)}>
                  <a
                     
                     className="2xl:py-0 sm:py-0 py-2 px-6 tracking-wide text-[16px] font-medium duration-300 text-gray-500 hover:text-black hover:underline hover:decoration-orange-theme decoration-white underline-offset-8"
                  >
                     TOOLS
                  </a>
                  {dropdownStatus ? <IoIosArrowDown color='black' /> : <IoIosArrowForward color='black'/>}
               </div>
               {dropdownStatus && <div className='text-[12px] flex flex-col pl-4 gap-2 pt-2'>
                  <a href="/pdf-compressor"
                     className="2xl:py-0 sm:py-0 py-2 px-6 tracking-wide text-[16px] font-medium duration-300 text-gray-500 hover:text-black hover:underline hover:decoration-orange-theme decoration-white underline-offset-8"
                  >
                     PDF Compressor
                  </a>
                  <a href="/qr-code-generator"
                     className="2xl:py-0 sm:py-0 py-2 px-6 tracking-wide text-[16px] font-medium duration-300 text-gray-500 hover:text-black hover:underline hover:decoration-orange-theme decoration-white underline-offset-8"
                  >
                     QR Code Generator
                  </a>
                  <a href="/strong-password-generator"
                     className="2xl:py-0 sm:py-0 py-2 px-6 tracking-wide text-[16px] font-medium duration-300 text-gray-500 hover:text-black hover:underline hover:decoration-orange-theme decoration-white underline-offset-8"
                  >
                     Strong Password Generator
                  </a>
                  <a href="/css-box-shadow-generator"
                     className="2xl:py-0 sm:py-0 py-2 px-6 tracking-wide text-[16px] font-medium duration-300 text-gray-500 hover:text-black hover:underline hover:decoration-orange-theme decoration-white underline-offset-8"
                  >
                     Box Shadow Generator
                  </a>
                  <a href="/screen-resolution-checker"
                     className="2xl:py-0 sm:py-0 py-2 px-6 tracking-wide text-[16px] font-medium duration-300 text-gray-500 hover:text-black hover:underline hover:decoration-orange-theme decoration-white underline-offset-8"
                  >
                     Screen Resolution Checker
                  </a>
               </div>}
            </div>
            <div className='px-4'>
               <hr className='border-black border-light my-2'></hr>
            </div>
            <div className='mob-sidebar-item'>
               <a
                  href="mailto:mak@maktalseo.com"
                  className="2xl:py-0 sm:py-0 py-2 px-6 tracking-wide text-[16px] font-medium duration-300 text-gray-500 hover:text-black hover:underline hover:decoration-orange-theme decoration-white underline-offset-8"
               >
                  CONTACT
               </a>
            </div>
            {/* <div className="2xl:py-0 sm:py-0 py-2 px-4">
            <LoginButton />
          </div> */}
         </div>
   )
}

export default MobMenuContent