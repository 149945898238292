

const generateMailStr = (mail: string) => `mailto:${mail}`

const generatePhStr = (ph: string) => `tel:${ph}`

export type QRContactDataType = {
    // firstName?: string,
    // lastName?: string,
    name?:string,
    org?: string
    title?: string,
    email?: string,
    url?: string,
    ph?: string,
    mobPh?: string,
    fax?: string,
    address?: {
        street?: string,
        city?: string,
        region?: string,
        postcode?: string,
        country?: string,
    }
}
const generateVCardStr = (data: QRContactDataType) => `BEGIN:VCARD\nVERSION:3.0${
    data.name ? `\nN:${data.name}` : ''
}${
    data.org ? `\nORG:${data.org}` : ''
}${
    data.title ? `\nTITLE:${data.title}` : ''
}${
    data.email ? `\nEMAIL;TYPE=INTERNET:${data.email}` : ''
}${
    data.url ? `\nURL:${data.url}` : ''
}${
    data.ph ? `\nTEL;TYPE=CELL:${data.ph}` : ''
}${
    data.mobPh ? `\nTEL:${data.mobPh}` : ''
}${
    data.fax ? `\nTEL;TYPE=FAX:${data.fax}` : ''
}${
    data.address ? `\nADR:;;${
        data.address.street ?? ''
    }; ${
        data.address.city ?? ''
    };${
        data.address.region ?? ''
    };${
        data.address.postcode ?? ''
    };${
        data.address.country ?? ''
    }` : ''
}\nEND:VCARD`


export type QRSMSDataType = {
    ph?: string, message?: string
}
const generateSMSStr = (data: QRSMSDataType) => `SMSTO:${data.ph ?? ''}${data.message ? `:${data.message}` : ''}`

const qrcode = {
    generateMailStr, generatePhStr, generateSMSStr, generateVCardStr
}

export default qrcode