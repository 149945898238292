import React from 'react'
import Header from '../components/Header/Header'
import Intermediate5 from '../components/Intermediate/Intermediate5'
import Footer from '../components/Footer/Footer'
import ReactHelmet from '../components/ReactHelmet'

const PasswordGenerator = () => {
  return (
    <React.Fragment>
      <ReactHelmet title='Strong Password Generator Online - Free SEO Tools' description='Generate secure and random passwords with this password generator tool. Ensure the safety of your online accounts by creating strong passwords effortlessly."'/>
      
      <Header />
      <Intermediate5 />
      <Footer />
    </React.Fragment>
  )
}

export default PasswordGenerator