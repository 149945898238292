import React from 'react'

const CompressButton = () => {
  return (
    <div>
        <div className="font-medium bg-orange-theme duration-300 hover:bg-black py-4 px-6 rounded text-white border cursor-pointer" >
         Compress PDF
       </div>
    </div>
  )
}

export default CompressButton