import React from 'react'

const QRFormText: React.FC<QRFormComponentProps> = ({ onSubmit }) => {
  return (
    <div className="md:px-4 sm:px-0 px-0 dark:bg-gray-800">
      <div className='md:ml-6 sm:ml-0 ml-0 min-h-[20rem] sm:p-8 p-4 bg-white shadow-xl rounded-md'>
        <p className='text-orange-theme font-medium text-sm'>Enter Text</p>
        <div className="relative">
          <textarea onChange={e => onSubmit && onSubmit(e.currentTarget.value)} id="message" rows={8} className=" mt-6 block p-2.5 w-full text-sm text-gray-900 bg-white rounded-lg border border-gray-300 focus:ring-transparent focus:border-gray-900 min-h-20" placeholder="Write your Content here..."></textarea>
          <div className="absolute bottom-0 left-0 right-0 h-1 bg-blue-500 transform scale-x-0 transition-transform"></div>
        </div>
      </div>
    </div>
  )
}

export default QRFormText