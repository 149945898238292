

import React, { useState } from 'react'

const QRFormUrl: React.FC<QRFormComponentProps> = ({ onSubmit }) => {
  const [valid, setValid] = useState(true)
  return (
    <div className="md:px-4 sm:px-0 px-0 dark:bg-gray-800">
      <div className='md:ml-6 sm:ml-0 ml-0 sm:min-h-[20rem] min-h-[12rem] sm:p-8 p-4 bg-white shadow-xl rounded-md'>
        <p className='text-orange-theme font-medium text-sm'>Enter URL</p>
        <div className="relative">
          <input
            onChange={e => {
              if (!(/\w+\.[A-Za-z]+$/.test(e.target.value))) {
                setValid(false)
              }else{
                setValid(true)
              }
              onSubmit && onSubmit(e.currentTarget.value)
            }}
            type="text" title='a' className="px-0 bg-transparent border-transparent border-b-gray-300 focus:outline-transparent focus:border-transparent focus:border-b-orange-theme ring-transparent focus:ring-transparent outline-none focus:border-orange-theme w-full" />
            {!valid ? (
          <div style={{ color: '#f87171' }}>Invalid Url</div>
        ) : null}
          <div className="absolute bottom-0 left-0 right-0 h-1 bg-blue-500 transform scale-x-0 transition-transform"></div>
        </div>
      </div>
    </div>
  )
}

export default QRFormUrl