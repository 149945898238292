import React, { useEffect, useState } from 'react';
import pdfcompressor from '../../assets/images/pdf2.png';
import CompressButton from '../Buttons/CompressButton';
import { FaCheckCircle } from 'react-icons/fa';
import Header3 from '../Header/Header3';
import { useLocation, useNavigate } from 'react-router';
import pdfCompressApi from '../../api/pdfCompressApi';
import { AiFillCloseCircle } from 'react-icons/ai'

export type CompressedFileType = { [key: number]: any }

export type ProgressType = {
  [key: number]: number
}

export type FileQuality = 'low' | 'medium' | 'high'

const getFileSize = (files: FilesResponseType[]) => {
  let inputSize = 0
  let outputSize = 0
  for (const file of files) {
    inputSize += file.inputSize / 1024
    outputSize += file.outputSize / 1024
  }
  const compressedPercent = 100 - ((outputSize / inputSize) * 100)
  return {
    compressedPercent,
    inputSize: Math.ceil(inputSize),
    outputSize: Math.ceil(outputSize)
  }
}

const getUploadFilesCumilatedSize = (files: File[]) => {
  const sizeMap: { [key: number]: number } = { 1: 0 }
  var i = 1
  for (const file of files) {
    sizeMap[i] = i === 1 ? Math.ceil(file.size) : sizeMap[i - 1] + Math.ceil(file.size)
    i++
  }
  return sizeMap
}
// const getFileUploadIndex = (map:{[key:number]:number},size:number) =>{

// }
const Intermediate4 = () => {
  const location: { state: { files: File[] } } = useLocation()
  const navigate = useNavigate()


  const [compressionOption, setCompressionOption] = useState<FileQuality>('medium');
  const [uploadFiles, setUploadFiles] = useState<File[]>([])
  console.log(uploadFiles)
  const [fileSizeCum, setFileSizeCum] = useState<{ [key: number]: number }>({})
  const [progressStatus, setProgressStatus] = useState<{
    onClick: boolean,
    progress: number,
    error?: any,
    estimatedTime: number,
    fileUploading: { index: number, name?: string }
    rate: number
  }>({ onClick: false, progress: 0, fileUploading: { index: 1, name: '' }, rate: 0, estimatedTime: 0 })

  const handleCompressionOptionChange = (option: FileQuality) => {
    setCompressionOption(option);
  };
  useEffect(() => {

    setUploadFiles(location.state.files)
  }, [location.state.files])

  useEffect(() => {
    setFileSizeCum(getUploadFilesCumilatedSize(uploadFiles))
  }, [uploadFiles])



  return (progressStatus.onClick) ? (
    <div className='flex flex-col justify-center items-center px-4 lg:px-[300px] mt-16'>
      {(progressStatus.progress !== 1) ?
        (<>
          <div className='mb-4 text-center'>
            <p className='font-bold mb-2'>{`Uploading file ${progressStatus.fileUploading.index} of ${uploadFiles.length}`} </p>
            {progressStatus.fileUploading.name && <p className=' mb-2 text-[14px]'>{`${progressStatus.fileUploading.name}(${(uploadFiles[progressStatus.fileUploading.index-1].size/(1024*1024)).toFixed(2)} MB)`}</p>}
            <p className='font-bold'>Time left <span className='text-cyan-900 font-thin'>{progressStatus.estimatedTime > 60 ? `${(progressStatus.estimatedTime/60).toFixed(1)} MINUTES`:`${progressStatus.estimatedTime} SECONDS`}</span> - Uploading speed <span className='text-cyan-900 font-thin'>{`${progressStatus.rate < 1024 ? `${progressStatus.rate.toFixed(1)} KB/S` : `${(progressStatus.rate / 1024).toFixed(1)} MB/S`} `}</span> </p>
          </div>

          <div className="w-full bg-gray-200  dark:bg-gray-700">
            <div className=" bg-orange-theme text-xs font-medium text-black text-center p-2 leading-none" style={{ width: `${progressStatus.progress * 100}%` }}>

            </div>
          </div>
          <div className='mt-4 font-bold text-center text-cyan-900'>
            <p className='text-[30px] text-center'>{`${(progressStatus.progress * 100).toFixed(0)}%`}</p>
            <p>Uploaded</p>
            {/* <p>No of Files : {uploadFiles.length}</p> */}
          </div>
        </>
        ) : (
          <div className='text-center'>
            <p className='font-bold text-cyan-900 mb-2' >Compressing PDF...</p>
            <div role="status">
              <svg aria-hidden="true" className="inline w-12 h-12 mr-2 text-gray-200 animate-spin dark:text-gray-600 fill-orange-theme" viewBox="0 0 100 101" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z" fill="currentColor" />
                <path d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z" fill="currentFill" />
              </svg>
              <span className="sr-only">Loading...</span>
              <p className='mt-2' >Do not close your browser. Wait until files are uploaded and processed! This might take a few minutes</p>
            </div>
          </div>
        )}
    </div>
  ) : (
    <div>
      <Header3 />
      <div className="bg-orange-75 w-[99.9%] relative flex-row flex items-center md:flex-nowrap sm:flex-wrap flex-wrap justify-end">
        <div className='overflow-hidden 2xl:w-8/12 xl:w-8/12 md:w-6/12 sm:w-full min-w-[60vw] w-full flex justify-center items-center relative h-full min-h-[50vh]'>

          <div className='flex flex-wrap justify-center'>
            {uploadFiles && uploadFiles.map((val, index) => {
              return (
                <div key={index} className="flex flex-col items-center p-2">

                  <div className='2xl:w-36 sm:w-36 w-20 relative'>
                    <div className='absolute -right-2 -top-2 cursor-pointer'
                      onClick={() => {
                        setUploadFiles(uploadFiles.filter((e, i) => i !== index))
                      }}
                    >
                      <AiFillCloseCircle color='orange-theme' size={20} />
                    </div>
                    <img src={pdfcompressor} alt="MyLogo" className="2xl:w-36 sm:w-36 w-20 2xl:h-36 sm:h-36 h-20 bg-white 2xl:p-12 sm:p-12 p-4" />
                    {/* {
                    progress[index] && 
                    <div className="w-full bg-gray-200  h-2.5 dark:bg-gray-700">
                      <div className="bg-orange-theme h-2.5 " style={{ width: `${progress[index] * 100}%` }}></div>
                    </div>} */}
                  </div>
                  <p className='mt-2 max-w-[170px] whitespace-nowrap overflow-hidden text-ellipsis'>{val.name}</p>
                  {/* {
                    downloadedFile[index] !== undefined &&
                    <div className='flex px-3 py-1 text-sm rounded-md my-3 text-white bg-orange-400 hover:bg-black hover:text-white duration-300 cursor-pointer' onClick={() => downloadPdf(downloadedFile[index], val.name)}>
                      Download
                      <svg xmlns="http://www.w3.org/2000/svg" width="" height="20" viewBox="0 0 24 24"><path fill="currentColor" d="M16.707 13.293a.999.999 0 0 0-1.414 0L13 15.586V8a1 1 0 1 0-2 0v7.586l-2.293-2.293a.999.999 0 1 0-1.414 1.414L12 19.414l4.707-4.707a.999.999 0 0 0 0-1.414z" />
                      </svg>

                    </div>
                  } */}

                </div>
              )
            })}
          </div>


          <div className="absolute flex flex-col md:top-2 sm:top-6 top-6 md:right-10 sm:right-2 right-6">
            <label htmlFor="add-file">
              <input
                title='add-file'
                id='add-file'
                type="file"
                className='hidden'
                accept='.pdf'
                multiple
                onChange={(e) => {
                  const files = e.currentTarget.files;
                  if (files != null) {
                    const value: File[] = []
                    for (let i = 0; i < files.length; i++) {
                      value.push(files[i])
                    }
                    setUploadFiles((prev) => ([...prev, ...value]))
                  }
                }}
              />
              <div title='Add more files' data-tooltip-target="tooltip-left" data-tooltip-placement="left" className="cursor-pointer mb-4 bg-gray-700 hover:bg-orange-theme duration-300 text-white font-bold py-2 px-2 rounded-full h-12 w-12 flex items-center justify-center">
                <svg xmlns="http://www.w3.org/2000/svg" className="h-5 w-5 inline-block" viewBox="0 0 20 20" fill="currentColor">
                  <path d="M11 9V4a1 1 0 1 0-2 0v5H4a1 1 0 1 0 0 2h5v5a1 1 0 1 0 2 0v-5h5a1 1 0 1 0 0-2h-5z" clip-rule="evenodd" />
                </svg>
              </div>
            </label>

            <div id="tooltip-left" role="tooltip" className="absolute w-40 z-10 invisible inline-block px-4 py-3 text-md font-medium text-white bg-cyan-900 rounded-md shadow-sm opacity-0 tooltip dark:bg-gray-700">
              Add More Files
              <div className="tooltip-arrow" data-popper-arrow></div>
            </div>

            {/* <button title='submit' className="bg-gray-700 hover:bg-orange-theme duration-300 text-white font-bold py-2 px-2 rounded-full h-12 w-12">
              <svg width="21" height="20" viewBox="0 0 21 20" className="h-5 w-5 inline-block" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M-0.00012207 2.10523C-0.00012207 1.54688 0.221127 1.0114 0.614954 0.616587C1.00878 0.221773 1.54292 -3.05176e-05 2.09988 -3.05176e-05H18.8999C19.4568 -3.05176e-05 19.991 0.221773 20.3848 0.616587C20.7786 1.0114 20.9999 1.54688 20.9999 2.10523V13.6842C20.9999 14.2425 20.7786 14.778 20.3848 15.1728C19.991 15.5676 19.4568 15.7894 18.8999 15.7894H11.5499V17.8947H14.6999C14.9784 17.8947 15.2454 18.0056 15.4423 18.203C15.6393 18.4004 15.7499 18.6682 15.7499 18.9473C15.7499 19.2265 15.6393 19.4943 15.4423 19.6917C15.2454 19.8891 14.9784 20 14.6999 20H6.29988C6.0214 20 5.75433 19.8891 5.55742 19.6917C5.3605 19.4943 5.24988 19.2265 5.24988 18.9473C5.24988 18.6682 5.3605 18.4004 5.55742 18.203C5.75433 18.0056 6.0214 17.8947 6.29988 17.8947H9.44988V15.7894H2.09988C1.54292 15.7894 1.00878 15.5676 0.614954 15.1728C0.221127 14.778 -0.00012207 14.2425 -0.00012207 13.6842V2.10523ZM18.8999 13.6842V2.10523H2.09988V13.6842H18.8999Z" fill="#FFFFFE" />
              </svg>
            </button> */}
          </div>

        </div>
        <div className="2xl:w-4/12 xl:w-4/12 md:w-6/12 sm:w-full w-full border-l border-slate-300 bg-slate-50 flex h-full flex-col justify-between min-h-[70vh]">
          <div>
            <div className="h-[6rem] text-gray-900 border-b border-slate-300 flex items-center justify-center font-medium text-2xl">
              COMPRESSION LEVEL
            </div>
            <div
              className={`py-[2rem] border-b border-slate-300 flex items-center text-sm cursor-pointer ${compressionOption === 'low' ? 'bg-stone-100' : ''
                }`}
              onClick={() => handleCompressionOptionChange('low')}
            >
              <p className="ml-10 flex-grow">
                <span className="text-orange-theme text-lg mb-12">EXTREME COMPRESSION</span>
                <br />
                <span className='text-[16px] text-gray-900'>Less Quality, high compression</span>

              </p>
              {compressionOption === 'low' && <FaCheckCircle className="mr-7 w-8 h-8 text-green-400" />}
            </div>
            <div
              className={`py-[2rem] border-b border-slate-300 flex items-center text-sm cursor-pointer ${compressionOption === 'medium' ? 'bg-stone-100' : ''
                }`}
              onClick={() => handleCompressionOptionChange('medium')}
            >
              <p className="ml-10 flex-grow">
                <span className="text-orange-theme text-lg mb-12">RECOMMENDED COMPRESSION</span>
                <br />
                <span className='text-[16px] text-gray-900'>Balanced Quality and compression</span>

              </p>

              {compressionOption === 'medium' && <FaCheckCircle className="mr-7 w-8 h-8 text-green-400" />}
            </div>
            <div
              className={`py-[2rem] border-b border-slate-300 flex items-center text-sm cursor-pointer ${compressionOption === 'high' ? 'bg-stone-100' : ''
                }`}
              onClick={() => handleCompressionOptionChange('high')}
            >
              <p className="ml-10 flex-grow">
                <span className="text-orange-theme text-lg mb-12">LESS COMPRESSION</span>
                <br />
                <span className='text-[16px] text-gray-900'>High Quality, less compression</span>

              </p>

              {compressionOption === 'high' && <FaCheckCircle className="mr-7 w-8 h-8 text-green-400" />}
            </div>
          </div>
          <div className="flex flex-col items-center justify-center pb-10 mt-10">
            {uploadFiles.length > 0 && <div onClick={() => {
              const startTime = Date.now()
              let uploadingFile = 1
              pdfCompressApi.compress(uploadFiles, compressionOption, (progressEvent) => {

                // const uploadingFile = progressEvent.total ? Math.ceil(progressEvent.loaded / (progressEvent.total / uploadFiles.length)) : 1
                // console.log(uploadingFile,'uplod')

                for (let i = uploadingFile; i <= uploadFiles.length; i = i + 1) {
                  if (progressEvent.loaded < fileSizeCum[i]) {
                    uploadingFile = i
                    break;
                  }
                }
                const rate = (progressEvent.loaded / ((Date.now() - startTime)/1000))
                console.log(rate, 'rate')
                setProgressStatus((prev) => ({
                  onClick: true,
                  progress: progressEvent.progress ?? 0,
                  fileUploading: { index: uploadingFile, name: uploadFiles[uploadingFile - 1]?.name ?? '' },
                  rate: rate / 1024,
                  estimatedTime: Math.round((fileSizeCum[uploadFiles.length] - progressEvent.loaded) / (rate))
                }))
              }).then((response) => {
                navigate('/pdf-compressor/download', {
                  state: {
                    downloadUrl: response.data.downloadUrl,
                    compressedSize: getFileSize(response.data.files).outputSize,
                    fileSize: getFileSize(response.data.files).inputSize,
                    single: response.data.files.length === 1
                  }
                })

              })
                .catch((err) => {

                  setProgressStatus({ onClick: false, progress: 0, error: err, fileUploading: { index: 1, name: '' }, rate: 0, estimatedTime: 0 })
                })
              // uploadFiles.forEach((val, index) => {
              //   if(progress[index] !==1)
              //   pdfCompressApi.compress(val, compressionOption, (progressEvent) => {
              //     setProgress((prev) => ({ ...prev, [index]: progressEvent.progress ?? 0 }))
              //   }).then((response) => {
              //     setDownloadLink(response.data)
              //   })
              // })
            }}>
              <CompressButton />
            </div>}
            {progressStatus.error && <p className='mt-2 text-white font bold bg-[red] rounded-md px-2 py-1'>Something Went Wrong</p>}

          </div>
        </div>
      </div>
    </div>
  );
};

export default Intermediate4;
