import { useField } from "formik";
import React from "react";

const FormikInputField: React.FC<React.DetailedHTMLProps<React.InputHTMLAttributes<HTMLInputElement>, HTMLInputElement> & { label: string,labelColour?: string,inputStyle?: string }> =
  ({ label,name,labelColour,inputStyle, ...props }) => {
    const [field, meta, actions] = useField(label);
    return (
      <div className="w-full">
      <label className={`${labelColour} block mb-2 text-sm font-medium text-gray-900 dark:text-white`}>{name}</label>
        <input 
        className={`${inputStyle} bg-transparent border-b border-gray-300 focus:outline-transparent focus:border-transparent focus:border-b-orange-theme ring-transparent focus:ring-transparent outline-none focus:border-orange-theme w-full`} {...field} {...props} onTouchEnd={() => actions.setTouched(true)}/>
        {meta.error ? (
          <div style={{ color: '#f87171' }}>{meta.error}</div>
        ) : null}
      </div>
    );
  }

export default FormikInputField