import React, { useEffect, useState } from 'react'
import Header3 from '../Header/Header3'

const ScreenChecker = () => {
  const [screenResolution, setScreenResolution] = useState({ width: 0, height: 0 });

  useEffect(() => {
    const updateScreenResolution = () => {
      setScreenResolution({ width: window.innerWidth, height: window.innerHeight });
    };

    // Update screen resolution on window resize
    window.addEventListener('resize', updateScreenResolution);

    // Initial screen resolution on component mount
    updateScreenResolution();

    // Clean up event listener on component unmount
    return () => {
      window.removeEventListener('resize', updateScreenResolution);
    };
  }, []);

  return (
    <div className="bg-orange-50 flex flex-col justify-center items-center">
      <Header3 />
      <div className="flex flex-col container mx-auto justify-center items-center w-full">
        <div className='flex justify-center w-full pt-8 pb-2'>
          <h1 className='text-2xl text-center font-semibold text-cyan-900'>What is my Screen Resolution?</h1>
        </div>
        <div className='bg-white flex flex-col lg:w-9/12 md:w-full w-full mt-4 mb-8 p-14 shadow-2xl'>   
          <div className='flex text-center flex-row w-full sm:flex-nowrap flex-wrap'>
          <h2 className='text-2xl font-semibold full-width'>Your current resolution is:</h2>
          
          </div>
          <div className='flex justify-center flex-row w-full mt-10 sm:flex-nowrap flex-wrap'>
          
          <h2 className='text-3xl color-red font-semibold'>{screenResolution.width} x {screenResolution.height}px </h2>
          </div>
          <div className='flex justify-center text-center flex-row w-full mt-10 sm:flex-nowrap flex-wrap'>
          <h2 className='text-xl font-semibold'>Width: <span className='color-grey'>{screenResolution.width} pixels</span>, Height: <span className='color-grey'>{screenResolution.height} pixels</span></h2>
          </div>
          <div className=' text-center mt-10'>
              <h3 className='font-semibold text-cyan'>Is this my device's actual dimension?</h3>
              <p>The dimensions you see above are your browser's viewable screen resolution. To see your device's actual screen resolution, please maximise your browser window.</p>
          </div>
        </div>
       
      </div>

    </div>

  ) 
}

export default ScreenChecker