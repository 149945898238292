import React, { useEffect, useState } from 'react'
import { useLocation } from 'react-router'
import pdfCompressApi from '../../api/pdfCompressApi'
import { CompressedFileType, ProgressType } from './Intermediate4'
import { FaDownload, FaLongArrowAltRight } from 'react-icons/fa'
import { CircularProgressbar } from 'react-circular-progressbar';
import 'react-circular-progressbar/dist/styles.css';

type LocationType = {
    state: {
        downloadUrl: string,
        compressedSize: number,
        fileSize: number
        single:boolean
    }
}

// const getProgressStatus = (progerss: ProgressType) => {
//     let sum = 0
//     let index = 0
//     Object.values(progerss).forEach((val, i) => {
//         index = i
//         sum += val
//     })
//     return (sum === index)

// }



// const downloadPdf = (compFile: any, name: string) => {
//     // const result = await pdfCompressApi.download()
//     // const pdfBytes = new Uint8Array(downloadLink.length);
//     // for (let i = 0; i <downloadLink.length; i++) {
//     //   pdfBytes[i] = downloadLink.charCodeAt(i);
//     // }
//     // var file = new File([compFile], name, { type: 'application/zip', lastModified: Date.now() });
//     // console.log(file ,'file')
//     const blob = new Blob([compFile], { type: 'application/zip' })
//     console.log(blob, 'blob')
//     const url = URL.createObjectURL(blob)
//     const link = document.createElement('a')
//     link.href = url
//     // const pdfWindow = window.open()
//     // pdfWindow!.location.href = url
//     link.download = `compressed-${name}.zip`
//     document.body.appendChild(link);
//     link.click();
//     URL.revokeObjectURL(url)
// }
const Intermediate8 = () => {
    const location: LocationType = useLocation()
    const getCompressionPercent = () => {
        return Math.ceil(100 - ((location.state.compressedSize / location.state.fileSize) * 100))
    }

    const [downloadLink, setDownloadLink] = useState<string>(location.state.downloadUrl)





    // useEffect(() => {
    //     setProgressStatus(getProgressStatus(progress))
    // }, [progress])

    return (
        <div className='mt-[100px] flex flex-col items-center'>
            <div className='flex justify-between items-center px-3 py-1 text-sm rounded-md my-3 text-white bg-orange-400 hover:bg-black hover:text-white duration-300 cursor-pointer w-[300px] h-[50px]'
                onClick={() => {
                    if (downloadLink !== '')
                    window.open(downloadLink,'_self')
                        // pdfCompressApi.download(downloadLink)
                        //     .then((response) => {
                        //         downloadPdf(response.data, 'fileName')
                        //         setDownloadedFile((prev) => ({ ...downloadedFile, [0]: response.data }))
                        //     })
                }}
            >
                <p className='font-bold'>Download Compressed Files</p>
                <FaDownload />
                {/* <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 24 24"><path fill="currentColor" d="M16.707 13.293a.999.999 0 0 0-1.414 0L13 15.586V8a1 1 0 1 0-2 0v7.586l-2.293-2.293a.999.999 0 1 0-1.414 1.414L12 19.414l4.707-4.707a.999.999 0 0 0 0-1.414z" /></svg> */}
            </div>
            <div className='flex items-center gap-3 mt-4'>
                <div className='w-[60px] h-[60px]'>
                    <CircularProgressbar className='text-orange-theme' value={getCompressionPercent()} text={`${getCompressionPercent()}%`} />
                </div>
                <div className=' '>
                    <p >{`Your PDF ${location.state.single ? 'is' : 'are'} now ${getCompressionPercent()}% smaller!`}</p>
                    <div className='font-bold flex items-center gap-3'>
                    <p>{location.state.fileSize} KB</p>
                    < FaLongArrowAltRight />
                    <p >{location.state.compressedSize} KB</p>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Intermediate8