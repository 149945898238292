import React from 'react'
import Header1 from '../components/Header/Header'
import Footer from '../components/Footer/Footer'
import PrivacyPolicyPage from '../components/Intermediate/privacypolicypage'
import ReactHelmet from '../components/ReactHelmet'

const PrivPolicy = () => {
  return (
    <React.Fragment>
      <ReactHelmet title='Privacy Policy - Mak SEO Tools' description="Read our privacy policy statement to see what data we collect and how we use it."/>
      <Header1 />
      <PrivacyPolicyPage />
      <Footer />
    </React.Fragment>
  )
}

export default PrivPolicy