import React, { useEffect, useState } from 'react'
import CopyIcon from '../../assets/images/CopyIcon.png';
import DoneIcon from '../../assets/images/done.svg'

type CopyPasswordButtonPropsType = {
  onClick: React.MouseEventHandler<HTMLButtonElement>
}

const CopyPasswordButton: React.FC<CopyPasswordButtonPropsType> = ({onClick}) => {
  const [showCopied, setShowCopied] = useState(false)

  useEffect(() => {
    if(showCopied){
      setTimeout(() => {
        setShowCopied(false)
      }, 1000)
    }
  }, [showCopied])

  return (
    <div className=''>
        <button type='submit' onClick={(e) => {
          setShowCopied(true)
          onClick(e)
        }} className='relative pass-copy-btn py-5 px-10 bg-orange-theme text-white mb-10 rounded-md font-medium hover:bg-black duration-300'>Copy Password
        {showCopied && (
          <div className="flex items-center rounded-md absolute -top-14 -right-10 bg-green-400 rounded-sm px-4 py-3 ">
            <p className='text-sm pr-4 text-white'>Password Copied</p>
            <img src={DoneIcon} alt="copydone" className='bg-white rounded-xl w-4 h-4'/>
          </div>
        )}
        </button>
    </div>
  )
}

export default CopyPasswordButton
