import React from 'react'
import Header from '../components/Header/Header'
import Intermediate3 from '../components/Intermediate/Intermediate3'
import Footer from '../components/Footer/Footer'
import ReactHelmet from '../components/ReactHelmet'


const PdfCompressor = () => {
  return (
    <React.Fragment>
      <ReactHelmet title='Free PDF Compressor - Compress PDF Online' description='Reduce PDF file size effortlessly with our efficient PDF compressor tool. Compress large PDFs while maintaining quality, making them easy to share, store, and upload. "'/>
      <Header />
      <Intermediate3 />
      <Footer />
    </React.Fragment>
    
  )
}

export default PdfCompressor