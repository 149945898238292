import React from 'react'
import RefreshIcon from '../../assets/images/RefreshIcon.png';

type RefreshButtonPropsType = {
  onClick: React.MouseEventHandler<HTMLButtonElement>
}

const RefreshButton :React.FC<RefreshButtonPropsType>= ({onClick}) => {
  return (
    <div className='mr-0'>
     <button type='submit' onClick={onClick}>
       <img src={RefreshIcon} alt="MyLogo" className="w-6 h-6" />
     </button>
    </div>
  )
}

export default RefreshButton