import React from 'react'
import maktalimage1 from '../../assets/images/seo-tools-image.png'
import Header3 from '../Header/Header3'

const Intermediate1 = () => {
  return (
    <div className="bg-orange-75 h-50">
      <Header3 />
      <div className="container mx-auto py-10 min-h-[30vh] flex items-center justify-center">
        <div className="flex items-center justify-between flex-row 2xl:flex-nowrap sm:flex-nowrap flex-wrap 2xl:px-0 sm:px-0">
          <div className="2xl:w-7/12 sm:w-7/12 w-full">
            <h1 className="text-[32px] xl:text-[46px] text-cyan-900 font-medium xl:leading-[60px] leading-[46px]">Free SEO Tools Online</h1>
            <p className="text-cyan-900 mt-4 w-10/12 leading-[30px]">
            Boost your website's visibility and rankings with powerful SEO tools.
            </p>
          </div>
          <div className="2xl:w-4/12 sm:w-4/12 w-10/12 2xl:pl-32 pl-0 2xl:mt-0 sm:mt-0 mt-10">
            <img src={maktalimage1} alt="free seo tools" className="w-full h-auto mob-small" />
          </div>
        </div>
      </div>
    </div>
  )
}

export default Intermediate1