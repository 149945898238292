import { useEffect, useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';

const DropdownButton = () => {
  const [isOpen, setIsOpen] = useState(false);
  const [files, setFiles] = useState<File[]|null>(null)
  const navigate = useNavigate()

  useEffect(() => {
    if(files !== null) navigate('/pdf-compressor/preview',{state:{files}})
  }, [files])

  return (
    <div className="mt-10 relative">
      <label
      htmlFor='pdf-compress'
        className="inline-flex justify-center items-center bg-orange-theme text-white px-4 h-12 rounded"
        onClick={() => setIsOpen(!isOpen)}
      >
        <span className="mr-2 font-medium cursor-pointer">Select Files</span>
        {/* <hr className="border-l border-gray-300 h-12"></hr> */}
        {/* <svg className="w-8 h-6 ml-2 -mr-1" viewBox="0 0 20 20" fill="currentColor">
          <path fillRule="evenodd" d="M6.293 7.293a1 1 0 0 1 1.414 0L10 9.586l2.293-2.293a1 1 0 1 1 1.414 1.414l-3 3a1 1 0 0 1-1.414 0l-3-3a1 1 0 0 1 0-1.414z" clipRule="evenodd" />
        </svg> */}
        <svg xmlns="http://www.w3.org/2000/svg" className="h-5 w-5 inline-block" viewBox="0 0 20 20" fill="currentColor">
                <path d="M11 9V4a1 1 0 1 0-2 0v5H4a1 1 0 1 0 0 2h5v5a1 1 0 1 0 2 0v-5h5a1 1 0 1 0 0-2h-5z" clip-rule="evenodd" />
              </svg>
        <input 
        className='hidden' 
        type="file" 
        multiple
        id='pdf-compress' 
        accept='.pdf'
        onChange={(e)=>{
          const files = e.currentTarget.files;
          if (files != null) {
              const value: File[] = []
              for (let i = 0; i < files.length; i++) {
                  value.push(files[i])
              }
              setFiles(value)
          }
        }} />
      </label>
      {/* {isOpen && (
        <div className="absolute right-0 mt-2 w-48 bg-white rounded-md shadow-lg z-10">
          <a href="#a" className="block px-4 py-2 text-gray-800 hover:bg-gray-100">
            File 1
          </a>
          <a href="#a" className="block px-4 py-2 text-gray-800 hover:bg-gray-100">
            File 2
          </a>
          <a href="#a" className="block px-4 py-2 text-gray-800 hover:bg-gray-100">
            File 3
          </a>
        </div>
      )} */}
    </div>
  );
};

export default DropdownButton;
