import React from 'react'
import Intermediate1 from '../components/Intermediate/Intermediate1'
import Intermediate2 from '../components/Intermediate/Intermediate2'
import Footer from '../components/Footer/Footer'
import Header from '../components/Header/Header'
import ReactHelmet from '../components/ReactHelmet'

const WelcomePage = () => {
  return (
    <React.Fragment>
      <ReactHelmet title='Free SEO Tools - Increase your Website Visibility' description="Boost your website's performance and visibility with our comprehensive SEO tools. "/>
      <Header />
      <Intermediate1 />
      <Intermediate2 />
      <Footer />
    </React.Fragment>
  )
}

export default WelcomePage