import React, { useEffect, useState } from 'react'
import CopyPasswordButton from '../Buttons/CopyPasswordButton'
import CopyButton from '../Buttons/CopyButton'
import RefreshButton from '../Buttons/RefreshButton'
import Header3 from '../Header/Header3'
import passwordGen, { PasswordGenOptionsType } from '../../utils/passwordGen'
import RangeSlider from '../RangeSliders/RangeSlider'
import CopyIcon from '../../assets/images/CopyIcon.png'




const Intermediate5 = () => {
  const [charOptions, setCharOptions] = useState<PasswordGenOptionsType>({
    uppercase: true,
    lowercase: true,
    numbers: true,
    symbols: true
  })
  const [passLength, setPassLength] = useState(8)
  const [generated, setGenerated] = useState('')
  useEffect(() => {
    setGenerated(passwordGen.generate(charOptions, passLength))
  }, [charOptions, passLength])
  return (
    <div className="bg-orange-50 flex flex-col justify-center items-center">
      <Header3 />
      <div className="flex flex-col container mx-auto justify-center items-center w-full">
        <div className='flex justify-center w-full pt-8 pb-2'>
          <h1 className='text-2xl font-semibold text-cyan-900'>Strong Password Generator</h1>
        </div>
        <div className='border-b-8 border-black bg-orange-theme font-semibold flex flex-row lg:w-9/12 md:w-full w-full mt-6 px-8 h-[6rem] shadow-2xl items-center justify-between'>
          <div className='overflow-x-auto scrollbar-thin scrollbar-thumb-orange-100 scrollbar-track-orange-300 w-9/12 mr-4'><p className='text-white ml-0 text-xl whitespace-nowrap'>{generated}</p></div>
          <div className='sm:w-3/12 w-4/12 flex justify-end items-center'>
            <CopyButton 
            text='Password Copied'
            icon={<img src={CopyIcon}  alt="copy" className="w-6 h-6 " />}
            onClick={async _ => {
              navigator.clipboard.writeText(generated)
            }} />
            <RefreshButton onClick={_ => setGenerated(passwordGen.generate(charOptions, passLength))} />
          </div>
        </div>
        <div className='bg-white flex flex-col lg:w-9/12 md:w-full w-full mt-4 mb-8 p-14 shadow-2xl'>
          <div className=" border-b border-orange-theme  text-xl flex items-center">
            <h2 className='mb-6'>Customize your password</h2>
          </div>
          <div className='flex flex-row w-full mt-10 sm:flex-nowrap flex-wrap justify-between'>

            <div className='flex flex-col sm:w-6/12 w-full sm:pr-20 p-0 sm:mb-0 mb-10'>
              <p className='text-black text-lg font-normal'>Password Length</p>
              <div className="flex items-center mt-6">
                <input value={passLength} onChange={e => {
                  if (parseInt(e.target.value)<=40 || e.target.value === '')
                  setPassLength(parseInt(e.target.value))}
              } min={0} max={40} aria-label='a' type="number" id="my-input" name="my-input" className="w-12 mr-6 py-2 px-3 text-gray-700 border border-orange-theme rounded-md focus:outline-none  focus:border-orange-theme focus:ring-transparent" />
                <RangeSlider value={20} onChange={value => setPassLength(parseInt(value.toString()))} min={0} max={40} />
              </div>

            </div>

            {/* <div className='flex flex-col sm:w-3/12 w-full sml:ml-4 ml-0 sm:mb-0 mb-10'>
              <div className="flex items-center mb-6">
                <input id="red-radio" type="radio" value="" name="colored-radio" className="w-7 h-7 focus:text-orange-theme text-gray-300 bg-gray-100 border-gray-300 ring-gray-300 focus:ring-transparent dark:focus:ring-red-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600" />
                <label className="ml-4 text-lg font-normal text-gray-900 dark:text-gray-300">Easy to Say</label>
              </div>
              <div className="flex items-center mb-6">
                <input id="red-radio" type="radio" value="" name="colored-radio" className="w-7 h-7 focus:text-orange-theme text-gray-300 bg-gray-100 border-gray-300 ring-gray-300 focus:ring-transparent dark:focus:ring-red-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600" />
                <label className="ml-4 text-lg font-normal text-gray-900 dark:text-gray-300">Easy to Read</label>
              </div>
              <div className="flex items-center mb-6">
                <input id="red-radio" type="radio" value="" name="colored-radio" className="w-7 h-7 focus:text-orange-theme text-gray-300 bg-gray-100 border-gray-300 ring-gray-300 focus:ring-transparent dark:focus:ring-red-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600" />
                <label className="ml-4 text-lg font-normal text-gray-900 dark:text-gray-300">All Character</label>
              </div>
            </div> */}

            <div className='flex flex-col sm:w-3/12 w-full sm:mb-0 mb-10'>

              <div className="flex items-center mb-6">
                <input checked={charOptions.uppercase} onChange={e => setCharOptions(options => ({ ...options, uppercase: !options.uppercase }))} type="checkbox" id="checkbox1" name="checkbox-group" className="h-7 w-7 rounded-sm text-orange-theme focus:ring-transparent hover:cursor-pointer" />
                <label htmlFor="checkbox1" className="ml-4 text-black text-lg font-normal">Uppercase</label>
              </div>
              <div className="flex items-center mb-6">
                <input checked={charOptions.lowercase} onChange={e => setCharOptions(options => ({ ...options, lowercase: !options.lowercase }))} type="checkbox" id="checkbox2" name="checkbox-group" className="h-7 w-7 rounded-sm text-orange-theme focus:ring-transparent hover:cursor-pointer" />
                <label htmlFor="checkbox2" className="ml-4 text-black text-lg font-normal">Lowercase</label>
              </div>
              <div className="flex items-center mb-6">
                <input checked={charOptions.numbers} onChange={e => setCharOptions(options => ({ ...options, numbers: !options.numbers }))} type="checkbox" id="checkbox3" name="checkbox-group" className="h-7 w-7 rounded-sm text-orange-theme focus:ring-transparent hover:cursor-pointer" />
                <label htmlFor="checkbox3" className="ml-4 text-black text-lg font-normal">Numbers</label>
              </div>
              <div className="flex items-center mb-6">
                <input checked={charOptions.symbols} onChange={e => setCharOptions(options => ({ ...options, symbols: !options.symbols }))} type="checkbox" id="checkbox4" name="checkbox-group" className="h-7 w-7 rounded-sm text-orange-theme focus:ring-transparent hover:cursor-pointer" />
                <label htmlFor="checkbox4" className="ml-4 text-black text-lg font-normal">Symbols</label>
              </div>

            </div>

          </div>
        </div>
        <CopyPasswordButton 
        onClick={async _ => {
          navigator.clipboard.writeText(generated)
        }} />
      </div>

    </div>

  )
}

export default Intermediate5