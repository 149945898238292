import React, { useState } from 'react';
import maktallogo from '../../assets/images/logo.png'
import { Link, useLocation } from 'react-router-dom'
import LoginButton from '../Buttons/LoginButton';
import { Navbar, Dropdown } from 'flowbite-react';
import MobMenuContent from './MobMenuContent';
import { IoIosArrowDown } from 'react-icons/io';

function Header() {
  const location = useLocation()
  return (
    <header className="header-wrap bg-white text-white container mx-auto">
      <Navbar
        className="md:px-0 !sm:px-0 px-0 py-4 h-[5.5rem] fixed w-full z-20 top-0 flex justify-between items-center left-0 bg-white shadow-sm">
        <Navbar.Brand href="/">
          <img
            src={maktallogo}
            width="150"
            height="150"
            className="d-inline-block align-top sm:w-150 w-[7rem] main-logo"
            alt="mak seo tools"
          />

        </Navbar.Brand>
        <div className='z-10'>
          <Navbar.Toggle />
        </div>
        <Navbar.Collapse
          className='nav-link-wrap'>
          <div className=' hidden md:flex gap-6 items-center'>
            <Navbar.Link
              href="/"
              active={false}
              className={` ${location.pathname === '/' ? 'underline' : ''} 2xl:py-0 sm:py-0 py-2 px-6 tracking-wide text-[16px] font-medium duration-300 text-gray-500 hover:text-black hover:underline hover:decoration-orange-theme decoration-orange-theme underline-offset-8`}
            >
              HOME
            </Navbar.Link>
            {/* <Navbar.Link href="/navbars"
            className="hover:text-orange-theme 2xl:py-0 sm:py-0 py-2 px-6 tracking-wide text-[16px] font-medium duration-300 text-gray-500 hover:text-black hover:underline hover:decoration-orange-theme decoration-white underline-offset-8">
            ABOUT
          </Navbar.Link> */}
            <div className='drop-btn relative'
              onMouseOver={(e) => {
                if (window.innerWidth > 600 && document.getElementById('dropdown-item'))
                  document.getElementById("dropdown-item")!.style.display = 'block'
              }}
            >
              <div

                className=' mob-sidebar-item flex items-center justify-between pr-3 cursor-pointer text-black hover:text-orange-theme' >
                <a
                  href="/"
                  className="2xl:py-0 sm:py-0 py-2  tracking-wide text-[16px] font-medium duration-300  hover:underline  decoration-white underline-offset-8"
                >
                  TOOLS
                </a>
                <IoIosArrowDown />
              </div>
              <div
              className='hidden absolute py-[20px] right-[-60px] w-[340px]'  id='dropdown-item'
              onMouseLeave={(e) => {
                if (window.innerWidth > 600 && document.getElementById('dropdown-item'))
                  document.getElementById("dropdown-item")!.style.display = 'none'
              }}
              onMouseEnter={(e) => {
                if (window.innerWidth > 600 && document.getElementById('dropdown-item'))
                  document.getElementById("dropdown-item")!.style.display = 'block'
              }}
              >
                <div
                  className=' bg-white rounded-md  py-10 px-2 shadow'
                  >
                  <div className=' text-[12px] flex flex-col pl-4 gap-4 pt-2'>
                    <a href="/pdf-compressor"
                      className="2xl:py-0 sm:py-0 py-2 px-6 tracking-wide text-[15px] font-medium duration-300 text-gray-700 hover:text-black hover:underline hover:decoration-orange-theme decoration-white underline-offset-8"
                    >
                      PDF Compressor
                    </a>
                    <a href="/qr-code-generator"
                      className="2xl:py-0 sm:py-0 py-2 px-6 tracking-wide text-[15px] font-medium duration-300 text-gray-700 hover:text-black hover:underline hover:decoration-orange-theme decoration-white underline-offset-8"
                    >
                      QR Code Generator
                    </a>
                    <a href="/strong-password-generator"
                      className="2xl:py-0 sm:py-0 py-2 px-6 tracking-wide text-[15px] font-medium duration-300 text-gray-700 hover:text-black hover:underline hover:decoration-orange-theme decoration-white underline-offset-8"
                    >
                      Strong Password Generator
                    </a>
                    <a href="/css-box-shadow-generator"
                      className="2xl:py-0 sm:py-0 py-2 px-6 tracking-wide text-[15px] font-medium duration-300 text-gray-700 hover:text-black hover:underline hover:decoration-orange-theme decoration-white underline-offset-8"
                    >
                      Box Shadow Generator
                    </a>
                    <a href="/screen-resolution-checker"
                      className="2xl:py-0 sm:py-0 py-2 px-6 tracking-wide text-[15px] font-medium duration-300 text-gray-700 hover:text-black hover:underline hover:decoration-orange-theme decoration-white underline-offset-8"
                    > Screen Resolution Checker
                    </a>
                  </div>
                </div>
              </div>
              {/* <Dropdown

                label="TOOLS"
                dismissOnClick={true}
                className='mt-2 opacity-[1] bg-white hover:text-orange-theme nav-drop-btn mr-4 text-[16px] font-medium duration-300 text-gray-500 hover:decoration-orange-theme decoration-white underline-offset-8'
                arrowIcon={true}
              >
                <div
                  onMouseLeave={(e) => {
                    if (document.getElementById(':r2:'))
                      document.getElementById(':r2:')!.style.visibility = 'hidden'
                  }}
                >
                  <Dropdown.Item>
                    <Navbar.Link href="/PdfCompressor" className='text-[16px] mt-2'>
                      PDF Compressor
                    </Navbar.Link>
                  </Dropdown.Item>

                  <Dropdown.Item>
                    <Navbar.Link href="/QRCodeGenerator" className='text-[16px] mt-2'>
                      QR Code Generator
                    </Navbar.Link>
                  </Dropdown.Item>

                  <Dropdown.Item>
                    <Navbar.Link href="/PasswordGenerator" className='text-[16px] mt-2'>
                      Strong Password Generator
                    </Navbar.Link>
                  </Dropdown.Item>

                  <Dropdown.Item>
                    <Navbar.Link href="/BoxShadowGenerator" className='text-[16px] mt-2 hover:text-orange-theme'>
                      Box Shadow Generator
                    </Navbar.Link>
                  </Dropdown.Item>
                </div>
              </Dropdown> */}
            </div>
            {/* <div className="2xl:py-0 sm:py-0 py-2 px-4">
            <LoginButton />
          </div> */}

<Navbar.Link
              href="mailto:mak@maktalseo.com"
              className=' 2xl:py-0 sm:py-0 py-2 px-6 tracking-wide text-[16px] font-medium duration-300 text-gray-500 hover:text-black hover:underline hover:decoration-orange-theme decoration-orange-theme underline-offset-8'
            >
              CONTACT
            </Navbar.Link>
          </div>
          <div className='realative md:hidden'>
            <MobMenuContent />
          </div>

        </Navbar.Collapse>

      </Navbar>




    </header>
  );
}

export default Header;