import React, { ReactNode } from 'react'
type QrTabPropType = {
  onClick:()=>void
  currentTab:boolean,
  title:string,
  icon:ReactNode
}
const QrTab:React.FC<QrTabPropType> = ({onClick,currentTab,title,icon}) => {
  return (
    <li className="xl:ml-8 md:ml-4 sm:ml-4 ml-0 mb-6 md:mb-0" role="presentation">
      <button onClick={() => onClick()} className="qr-type-item rounded-md sm:ml-6 ml-0 mb-2 lg:pl-8 md:pl-4 sm:pl-4 pl-0 sm:pr-8 pr-0 justify-center flex sm:justify-end items-center sm:w-full w-20 h-20 bg-white shadow-sm border-b-2 border-white hover:text-orange-theme  focus:text-orange-theme duration-300 hover:border-orange-theme cursor-pointer text-gray-500">
        <p className={`ml-4 md:block sm:hidden hidden text-lg ${currentTab ? 'text-orange-theme' : 'text-ico'}`}>{title}</p>
        {icon}
      </button>
      <p className='text-orange-theme  md:hidden'>{title}</p>
    </li>
  )
}

export default QrTab