import React from 'react'
import Header from '../components/Header/Header'
import Intermediate7 from '../components/Intermediate/Intermediate7'
import Footer from '../components/Footer/Footer'
import ReactHelmet from '../components/ReactHelmet'

const QRCodeGenerator = () => {
  return (
    <React.Fragment>
      <ReactHelmet title='Free QR Code Generator - Generate QR Code Online' description='Generate QR codes quickly and easily with this QR code generator. Create custom codes for URLs, text, contact information, and more for efficient sharing and information retrieval."'/>
      <Header />
      <Intermediate7/>
      <Footer />
    </React.Fragment>
  )
}

export default QRCodeGenerator