import React from 'react'
import maktallogo from '../../assets/images/logo.png'
import { Link } from 'react-router-dom'
//import BackButton from '../Buttons/BackButton'

const Header3 = () => {
  return (
        <div className='w-full h-[5.5rem] bg-white'>
        </div>
  )
}

export default Header3